<script setup lang="ts">
definePage({
    name: 'conments-edit',
    meta: {
        level: 2,
        title: 'conments-edit',
        i18n: 'orderEdit.text'
    },
})
import { showToast } from 'vant';
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const { t } = useI18n()

const route = useRoute()
let userinfo: LoginData = getUserInfo()

const order = ref(JSON.parse(route.query.data as string))

import GoodsImg from "@/pages/goods/img.vue";

import moment from 'moment' //导入模块
moment.locale('zh-cn')
import { uploadFile } from '@/api/upload';
import type { CommentReq } from '@/types/settings';
import { CommentGoods, deleteComment, queryCommentById } from '@/api/settings';
let btnState: boolean = false;

const list = order.value.commentList
const req = ref<CommentReq>({
  id: null,
  comment: '',
  commentPicList: [],
  goodsId: order.value.goodsId,
  goodsSkuId: order.value.goodsSkuId,
  level: list && list.length > 0 ? list[list.length - 1].level : 0,
  name: order.value.name,
  nordersDetailId: order.value.detailId,
  nordersId: order.value.nordersId,
  nuserId: userinfo.id,
  specName: order.value.skuName
})
const afterRead = async (file) => {
  if (req.value.commentPicList.length === 9) {
    showToast({
      message: t('orderEdit.picMaxNumber'),
      position: "bottom",
    });
    return
  }
  const { code, data } = await uploadFile(file.file, "onlineWeb", "");
  if (code == 200) {
    req.value.commentPicList.push({
      goodsCommentId: null,
      id: null,
      url: data.fileUrl
    })
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const router = useRouter()

function onBack() {
  if (window.history.state.back)
    history.back()
  else
    router.replace('/')
}

const submit = async ()=> {
  if (!req.value.level) {
    showToast({
      message: t('orderEdit.levelError'),
      position: "bottom",
    });
    return
  }
  if (!req.value.comment) {
    showToast({
      message: t('orderEdit.commentError'),
      position: "bottom",
    });
    return
  }
  if (btnState) {
    btnState = true
    return
  }
  let obj = {...req.value}
  if (obj.id === null) {
    delete obj.id
  }
  const { code } = await CommentGoods(obj);
  if (code == 200) {
    onBack()
    return;
  }
  btnState = false
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
}

const showTips = ref<boolean>(false)
let delId = null
const showDel = function(item) {
  showTips.value = true
  delId = item.id
}

const delComment = async function() {
  const { code } = await deleteComment(delId);
  if (code == 200) {
    for (let item of order.value.commentList) {
      await getComments(item)
    }
    showTips.value = false
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
}

const getComments = async (item) => {
  const { code, data } = await queryCommentById(item.id);
  if (code == 200) {
    item.commentList = data
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};
</script>
<template>
  <Container class="pl-0 pr-0">
    <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="dialog-info mb-6">{{ $t('orderEdit.deleteCommentInfo') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showTips = false">{{ $t('orderEdit.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="delComment" :disabled="btnState">{{ $t('orderEdit.confirm') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <VanNavBar :title="$t('orderEdit.text')" :fixed="true" clickable left-arrow @click-left="onBack">
      <template #right>
        <div text-14 fw-400 class="color-light-black" @click="submit">{{ $t('orderEdit.btn') }}</div>
      </template>
    </VanNavBar>
    <van-row mx-8 my-12 bg-white>
      <van-col span="24" pa-16 class="d-flex">
        <div w-60 h-60 mr-12>
            <GoodsImg
            :showType="order.showType"
            :color="order.color"
            :picUrl="order.picUrl"
            />
        </div>
        <div
            class="flex-column"
            flex
        >
            <div mb-8>
              {{ $t('orderEdit.goodsConments') }}  
            </div>
            <div>
              <van-rate v-model="req.level" color="rgb(249, 106, 27)" :readonly="order.commentList && order.commentList.length > 0"/>
              <span ml-8>{{ req.level ? $t(`orderEdit.type${req.level}`) : '' }}</span>
            </div>
        </div>
      </van-col>
    </van-row>
    <van-row mx-8 my-12 :gutter="20" v-if="order.commentList && order.commentList.length > 0">
      <van-col span="24" py-16 px-12 bg-white mb-12 class="d-flex flex-column" v-for="comment of order.commentList">
        <div span="24" text-12 fw-400 class="d-flex color-light-black" justify-between>
          <div>{{ comment.comment }}</div>
          <van-icon name="delete-o" color="orange" size="16" @click="()=>showDel(comment)" />
        </div>
        <van-row :gutter="20" mt-16 v-if="comment.commentPicList.length > 0">
          <van-col span="8" class="d-flex" v-for="file of comment.commentPicList">
            <van-image
              width="100%"
              height="110px"
              fit="contain"
              :src="file.url"
            />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row mx-8 my-12 bg-white :gutter="20" v-if="req.commentPicList.length > 0">
      <van-col span="8" pa-16 class="d-flex" v-for="file of req.commentPicList">
        <van-image
          width="100%"
          height="110px"
          fit="contain"
          :src="file.url"
        />
      </van-col>
    </van-row>
    <van-row mx-8 my-12 bg-white>
      <van-col span="24" pa-16 flex>
        <van-field
          v-model="req.comment"
          rows="5"
          autosize
          type="textarea"
          :placeholder="$t('orderEdit.textInfo')"
          maxlength="200"
          show-word-limit
        />
      </van-col>
      <van-col span="24" pa-16 flex>
        <van-uploader :after-read="afterRead" multiple/>
      </van-col>
    </van-row>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-uploader) {
  width: 100%;
}
:deep(.van-uploader__wrapper) {
  width: 100%;
}
:deep(.van-uploader__wrapper .van-uploader__upload) {
  width: 100%;
}
</style>
