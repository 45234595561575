<script setup lang="ts">
import car from '../assets/layout/car.png'
import car_check from '../assets/layout/car_check.svg'
import home from '../assets/layout/home.png'
import home_check from '../assets/layout/home_check.png'
import profile from '../assets/layout/profile.png'
import profile_check from '../assets/layout/profile_check.png'
import type_check from '../assets/layout/type_check.png'
import typelogo from '../assets/layout/typelogo.png'
const { t } = useI18n()
const active = ref(0)
const route = useRoute()

const display = computed(() => {
  if (route.meta.level && route.meta.level !== 2)
    return true
  return false
})
</script>

<template>
  <van-tabbar v-show="display" v-model="active" route active-color="#ff6000">
    <van-tabbar-item replace to="/">
      {{ t('layouts.home') }}
      <template #icon="props">
        <img :src="props.active ? home_check : home"/>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/type">
      {{ t('layouts.type') }}
      <template #icon="props">
        <img :src="props.active ? type_check : typelogo"/>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/car">
      {{ t('layouts.car') }}
      <template #icon="props">
        <img :src="props.active ? car_check : car"/>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/profile">
      {{ t('layouts.profile') }}
      <template #icon="props">
        <img :src="props.active ? profile_check : profile" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
