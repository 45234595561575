<script setup lang="ts">
import headphone1 from '@/assets/icon/headphone1.png';
import headphone from '@/assets/icon/headphone.png';
import scan from '@/assets/icon/scan.png';
import setting from '@/assets/icon/setting.png';
import coupon from '@/assets/icon/coupon.png';
import integral from '@/assets/icon/integral.png';
import wallet from '@/assets/icon/wallet.png';
import gift from '@/assets/icon/gift.png';
import wallet2 from '@/assets/icon/wallet2.png';
import refresh_outline from '@/assets/icon/refresh_outline.png';
import car from '@/assets/icon/car.png';
import check_calendar from '@/assets/icon/check_calendar.png';
import note from '@/assets/icon/note.png';
import return_square from '@/assets/icon/return_square.png';
import arrow_right_light from '../../assets/icon/arrow_right_light.png';
import questionnaire from '../../assets/icon/questionnaire.png';
import check_outline from '../../assets/icon/check_outline.png';
const active = ref(+localStorage.getItem('toposfy_online_h5_order_type_active_index') || 0);
const { t } = useI18n()

const tabs = ref([
  {
    title: t('profile.tab1'),
    data:[
      {type: 1, isDelivery: 1, num: 0, img: wallet2, name: t('profile.tab11')},
      {type: 2, isDelivery: 1, num: 0, img: refresh_outline, name: t('profile.tab12')},
      {type: 3, isDelivery: 1, num: 0, img: car, name: t('profile.tab13')},
      {type: 4, isDelivery: 1, num: 0, img: check_calendar, name: t('profile.tab14')},
      {type: 5, isDelivery: 1, num: 0, img: note, name: t('profile.tab15')},
      {type: 6, isDelivery: 1, num: 0, img: return_square, name: t('profile.tab16')},
    ]
  },
  {
    title: t('profile.tab2'),
    data:[
      {type: 1, isDelivery: 2, num: 0, img: wallet2, name: t('profile.tab21')},
      {type: 7, isDelivery: 2, num: 0, img: refresh_outline, name: t('profile.tab22')},
      {type: 4, isDelivery: 2, num: 0, img: check_calendar, name: t('profile.tab23')},
      {type: 5, isDelivery: 2, num: 0, img: note, name: t('profile.tab24')},
      {type: 6, isDelivery: 2, num: 0, img: return_square, name: t('profile.tab25')},
    ]
  }
])

const active2 = ref(0);
const tab2s = ref([
  {
    title: t('profile.tab3'),
    list: []
  },
  {
    title: t('profile.tab4'),
    list: []
  }
])

definePage({
  name: 'profile',
  meta: {
    level: 1,
  },
})
import type {LoginData} from '@/types/user';
import {getUserInfo, getAccountList, setUserInfo, getShopId} from '@/storage/localStorage';
let userinfo = ref<LoginData>(getUserInfo())
onMounted(()=>{
  if (userinfo.value !== null) {
    getUserData()
    getCollects()
    getHistory()
  }
})
import {getUserMsg} from '@/api/user';
import { showToast } from 'vant';
const getUserData = async function() {
  const { code, data } = await getUserMsg(userinfo.value.id)

  if (code == 200) {
    userinfo.value = data
    setUserInfo(data)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
}

const router = useRouter()
const toLogin = function() {
  const accounts = ref<Array<string>>(getAccountList())
  if (accounts.value && accounts.value.length > 0) {
    router.push('profile/accounts')
  } else {
    router.push('profile/login')
  }
}

const shopId = getShopId()
import {getUserCollect, getUserBrowse} from '@/api/settings';
const getCollects = async () => {
  const { code, data } = await getUserCollect(shopId, userinfo.value.id)
  if (code == 200) {
    tab2s.value[0].list = data.list
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const getHistory = async () => {
  const { code, data } = await getUserBrowse(shopId, userinfo.value.id)
  if (code == 200) {
    tab2s.value[1].list = data.list
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

import type { GoodsDetailRes } from '@/types/goods';

const toGoodsInfo = function(g: GoodsDetailRes) {
  router.push(`/home/goods/info?goodsId=${g.goodsId}&nGoodsId=${g.ngoodsId}&type=${g.type}`)
}

const toOrderList = function(g) {
  localStorage.setItem('toposfy_online_h5_order_type_active_index', active.value + '')
  router.push(`/profile/order/${g.type == 5 ? 'conments' : 'list'}?isDelivery=${g.isDelivery}&type=${g.type}`)
}
import GoodsImg from "@/pages/goods/img.vue";
</script>

<template>
  <Container :paddingT="0" :paddingX="0">
    <div class="d-flex flex-column pa-4" style="background-color: #fff;">
      <VanRow style="height: 28px;align-items: center;">
        <VanCol span="12" class="d-flex">
          <div v-if="Boolean(userinfo)" text-20 fw-500 class="ellipsis-space">{{ userinfo.name || userinfo.email }}</div>
          <VanCell v-else :title="t('profile.login')" @click="toLogin" is-link class="fz-5 fw-500"/>
        </VanCol>
        <VanCol span="12" class="d-flex justify-end">
          <img :src="headphone1" class="ml-4 img-24"/>
          <img :src="scan" class="ml-4 img-24"/>
          <RouterLink to="profile/settings">
            <img :src="setting" class="ml-4 img-24"/>
          </RouterLink>
        </VanCol>
      </VanRow>
      <VanRow class="d-flex mt-7 fz-3 fw-400 color-black" style="align-items: center;flex: 1;">
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="coupon" class="mb-2 img-20"/>
          <div>{{ t('profile.title1') }}</div>
        </VanCol>
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="integral" class="mb-2 img-20"/>
          <div>{{ t('profile.title2') }}</div>
        </VanCol>
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="wallet" class="mb-2 img-20"/>
          <div>{{ t('profile.title3') }}</div>
        </VanCol>
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="gift" class="mb-2 img-20"/>
          <div>{{ t('profile.title4') }}</div>
        </VanCol>
      </VanRow>
    </div>

    <div class="d-flex flex-column pa-0 mt-4" style="background-color: #fff;">
      <van-tabs class="pt-2" v-model:active="active" shrink title-active-color="#ff6000">
        <van-tab v-for="(item, index) of tabs" :key="index" :title="item.title">
          <VanRow class="d-flex fz-3 fw-400 pl-4 pr-4 pt-6 pb-0 color-black align-center" style="flex: 1;">
            <VanCol span="24" class="d-flex align-center justify-between flex-wrap">
              <div @click="()=>toOrderList(data)" style="min-width: 20%;" v-for="(data, i) of item.data" :key="i" class="mb-5 d-flex flex-column align-center jstify-center">
                <van-badge :content="data.num" max="99" :show-zero="false">
                  <img :src="data.img" class="mb-1 img-20"/>
                </van-badge>
                <div>{{ data.name }}</div>
              </div>
            </VanCol>
          </VanRow>
        </van-tab>
      </van-tabs>
    </div>

    <div class="d-flex flex-column pa-4 mt-4" style="background-color: #fff;">
      <VanRow style="height: 28px;align-items: center;">
        <VanCol span="12" class="d-flex fz-4 fw-500 color-black">
          {{ t('profile.moreService') }}
        </VanCol>
        <VanCol span="12" class="d-flex justify-end">
          <div class="fz-3 fw-400 color-light-black">{{ t('profile.all') }}</div>
          <img :src="arrow_right_light" class="img-16"/>
        </VanCol>
      </VanRow>
      <VanRow class="d-flex mt-7 fz-3 fw-400 color-black align-center" style="flex: 1;">
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="headphone" class="mb-2 img-20"/>
          <div>{{ t('profile.service1') }}</div>
        </VanCol>
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="questionnaire" class="mb-2 img-20"/>
          <div>{{ t('profile.service2') }}</div>
        </VanCol>
        <VanCol span="6" class="d-flex flex-column align-center jstify-center">
          <img :src="check_outline" class="mb-2 img-20"/>
          <div>{{ t('profile.service3') }}</div>
        </VanCol>
      </VanRow>
    </div>
    <div class="d-flex flex-column pa-0 mt-4 mb-8" style="background-color: #fff;min-height: 200px;">
      <van-tabs class="pt-2" v-model:active="active2" shrink title-active-color="#ff6000">
        <van-tab v-for="(item, index) of tab2s" :key="index" :title="item.title">
          <VanRow overflow-x-auto mt-8 ml-10 mr-10 class="flex-nowrap" v-if="item.list && item.list.length > 0">
              <VanCol mr-8 :span="8" v-for="(goods, i) of item.list" :key="i" @click="toGoodsInfo(goods)">
                <div w-120 h-120>
                  <GoodsImg
                    :showType="goods.showType"
                    :color="goods.color"
                    :picUrl="goods.picUrl"
                  />
                </div>
              </VanCol>
          </VanRow>
        </van-tab>
      </van-tabs>
    </div>
  </Container>
</template>
<style lang="less" scoped>
:deep(.van-tabs__nav) {
  padding: 0 0 4px 0 !important;
}
:deep(.van-tab--shrink) {
  padding: 0 !important;
  margin: 0 12px;
}
:deep(.van-tabs__wrap) {
  height: 26px !important;
}

:deep(.van-tab.van-tab--line.van-tab--shrink.van-tab--active) {
  border-bottom: 2px solid #ff6000;
}
:deep(.van-tabs__line) {
  display: none !important;
}
:deep(.van-badge) {
  border: 1px solid #ff6000;
  background-color: #fff !important;
  color: #ff6000 !important;
}
:deep(.van-cell) {
  padding: 0 !important;
  color: var(--color-black)
}
:deep(.van-cell__title) {
  width: fit-content;
  flex: unset;
}
:deep(.van-icon-arrow) {
  font-size: 22px;
  color: rgb(14, 16, 20)
}
</style>