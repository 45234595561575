<script setup lang="ts">
import { ref } from 'vue';
import { showToast } from 'vant';
definePage({
  name: 'register',
  meta: {
    level: 2,
    title: 'register',
    i18n: 'register.title',
  },
})
const router = useRouter()

import type { Register } from '@/types/user';
let showPwd = ref<boolean>(false)

let check1 = ref<boolean>(false)
let check2 = ref<boolean>(false)
let check3 = ref<boolean>(false)
const inputPwd = function () {
  if (param.pwd == '') {
    check1.value = false
    check2.value = false
    check3.value = false
    return
  }
  check1.value = param.pwd.length >= 8 && param.pwd.length <= 16
  check2.value = /[A-Za-z]+/.test(param.pwd)
  check3.value = /[0-9]+/.test(param.pwd)
}

const param = reactive<Register>({
  "email": "",
  "pwd": "",
  "langCode": 'zh_CN'
});
const { t } = useI18n()
let checked = ref<boolean>(false)
const checkForm = function () {
  if (!checked.value) {
    showToast({
      message: t('register.checkInfo'),
      position: 'bottom',
    });
    return false
  }
  if (param.email == '') {
    showToast({
      message: t('register.fillEmail'),
      position: 'bottom',
    });
    return false
  }
  if (param.pwd == '') {
    showToast({
      message: t('register.fillPwd'),
      position: 'bottom',
    });
    return false
  }
  if (!/^\S+(?:\s+\S+)*$/.test(param.email)) {
    showToast({
      message: `${t('register.email')}${t('register.spaceisNotAllown')}`,
      position: 'bottom',
    });
    return false
  }
  if (/\s+/g.test(param.pwd)) {
    showToast({
      message: `${t('register.password')}${t('register.spaceisNotAllown')}`,
      position: 'bottom',
    });
    return false
  }
  if (!check1.value || !check2.value || !check2.value) return false
  return true
}


import { register } from '@/api/user'
const onSubmit = async () => {
  if (!checkForm()) return
  const { code } = await register(param)

  if (code == 200) {
    router.push(`login-submit?loginAccount=${param.email}&source=1`)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
};

</script>

<template>
  <Container class="d-flex justify-between flex-column align-center" style="background-color: #fff;">

    <van-form style="width: 100%;">
      <van-cell-group inset style="margin: 0;">
        <van-field class="primary-input" :label="$t('register.email')" v-model="param.email" label-align="top"
          :clearable="true" clear-trigger="always" />

        <van-field class="primary-input" v-model="param.pwd" :type="showPwd ? 'text' : 'password'"
          :label="$t('register.password')" label-align="top" :clearable="true" clear-trigger="always"
          :rules="[{ required: true }]" :right-icon="showPwd ? 'eye-o' : 'closed-eye'"
          @click-right-icon="showPwd = !showPwd" @input="inputPwd" />
        <div class="check-pwd-tips" :class="check1 ? 'success' : ''">{{ $t('register.tips1') }}</div>
        <div class="check-pwd-tips" :class="check2 ? 'success' : ''">{{ $t('register.tips2') }}</div>
        <div class="check-pwd-tips" :class="check3 ? 'success' : ''">{{ $t('register.tips3') }}</div>
      </van-cell-group>
      <div class="mt-4 mb-4">
        <van-button type="warning" class="subject-btn" @click="onSubmit">{{ $t('register.title') }}</van-button>
      </div>
    </van-form>

    <van-checkbox class="mb-36" v-model="checked" icon-size="12px" checked-color="rgba(255, 74, 33, 1)">{{
      $t('register.check') }}</van-checkbox>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.van-field__body) {
  width: 100%;
}

:deep(.primary-input .van-cell__value.van-field__value) {
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 1);
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 500
}

.subject-btn {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background: var(--color-orange);
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.van-button__text) {
  display: flex;
  align-items: center;
  width: 100%;
}

:deep(.van-checkbox__label) {
  font-size: 12px;
  font-weight: 400;
  color: rgb(110, 110, 110)
}

.check-pwd-tips {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-orange);
}

.check-pwd-tips.success {
  color: var(--color-success);
}
</style>
