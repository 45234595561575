import _definePage_default_0 from 'D:/companyProject/qrs/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'D:/companyProject/qrs/src/pages/[...all].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from 'D:/companyProject/qrs/src/pages/car/index.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from 'D:/companyProject/qrs/src/pages/car/orderInfoDisplay.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from 'D:/companyProject/qrs/src/pages/car/orderInfoUnPay.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from 'D:/companyProject/qrs/src/pages/car/selectPayMethod.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from 'D:/companyProject/qrs/src/pages/home/shop/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from 'D:/companyProject/qrs/src/pages/home/shop/list.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from 'D:/companyProject/qrs/src/pages/home/shop/secondary-list.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from 'D:/companyProject/qrs/src/pages/profile/index.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from 'D:/companyProject/qrs/src/pages/profile/accountManage/index.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from 'D:/companyProject/qrs/src/pages/profile/accountManage/checkEmail.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from 'D:/companyProject/qrs/src/pages/profile/accountManage/delAccount.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from 'D:/companyProject/qrs/src/pages/profile/accountManage/fillCode.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from 'D:/companyProject/qrs/src/pages/profile/accountManage/setFirstPhone.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from 'D:/companyProject/qrs/src/pages/profile/accountManage/setNewEmail.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from 'D:/companyProject/qrs/src/pages/profile/accountManage/updatePwd.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from 'D:/companyProject/qrs/src/pages/profile/accounts.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from 'D:/companyProject/qrs/src/pages/profile/address/index.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from 'D:/companyProject/qrs/src/pages/profile/address/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from 'D:/companyProject/qrs/src/pages/profile/country-list.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from 'D:/companyProject/qrs/src/pages/profile/login.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from 'D:/companyProject/qrs/src/pages/profile/login-submit.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from 'D:/companyProject/qrs/src/pages/profile/order/conments.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from 'D:/companyProject/qrs/src/pages/profile/order/editeConments.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from 'D:/companyProject/qrs/src/pages/profile/order/info.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from 'D:/companyProject/qrs/src/pages/profile/register.vue?definePage&vue&lang.tsx'
import _definePage_default_28 from 'D:/companyProject/qrs/src/pages/profile/rules.vue?definePage&vue&lang.tsx'
import _definePage_default_29 from 'D:/companyProject/qrs/src/pages/profile/rules1.vue?definePage&vue&lang.tsx'
import _definePage_default_30 from 'D:/companyProject/qrs/src/pages/profile/settings.vue?definePage&vue&lang.tsx'
import _definePage_default_31 from 'D:/companyProject/qrs/src/pages/profile/userInfo/index.vue?definePage&vue&lang.tsx'
import _definePage_default_32 from 'D:/companyProject/qrs/src/pages/profile/userInfo/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_33 from 'D:/companyProject/qrs/src/pages/profile/userInfo/qrcode.vue?definePage&vue&lang.tsx'
import _definePage_default_34 from 'D:/companyProject/qrs/src/pages/type/index.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'home',
    component: () => import('D:/companyProject/qrs/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:all(.*)',
    name: '404',
    component: () => import('D:/companyProject/qrs/src/pages/[...all].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/car',
    /* internal name: '/car' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'car',
        component: () => import('D:/companyProject/qrs/src/pages/car/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'orderInfoDisplay',
        name: 'carOrderInfoDisplay',
        component: () => import('D:/companyProject/qrs/src/pages/car/orderInfoDisplay.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: 'orderInfoUnPay',
        name: 'orderInfoUnPay',
        component: () => import('D:/companyProject/qrs/src/pages/car/orderInfoUnPay.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: 'selectPayMethod',
        name: 'selectPayMethod',
        component: () => import('D:/companyProject/qrs/src/pages/car/selectPayMethod.vue'),
        /* no children */
      },
  _definePage_default_6
  )
    ],
  },
  {
    path: '/goods',
    /* internal name: '/goods' */
    /* no component */
    children: [
      {
        path: 'car',
        name: '/goods/car',
        component: () => import('D:/companyProject/qrs/src/pages/goods/car.vue'),
        /* no children */
      },
      {
        path: 'img',
        name: '/goods/img',
        component: () => import('D:/companyProject/qrs/src/pages/goods/img.vue'),
        /* no children */
      },
      {
        path: 'prices',
        name: '/goods/prices',
        component: () => import('D:/companyProject/qrs/src/pages/goods/prices.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/home',
    /* internal name: '/home' */
    /* no component */
    children: [
      {
        path: 'goods',
        /* internal name: '/home/goods' */
        /* no component */
        children: [
          {
            path: 'info',
            name: '/home/goods/info',
            component: () => import('D:/companyProject/qrs/src/pages/home/goods/info.vue'),
            /* no children */
          },
          {
            path: 'list',
            name: '/home/goods/list',
            component: () => import('D:/companyProject/qrs/src/pages/home/goods/list.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'shop',
        /* internal name: '/home/shop' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'edit',
            name: 'shop-list-edit',
            component: () => import('D:/companyProject/qrs/src/pages/home/shop/edit.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'list',
            name: 'shop-list',
            component: () => import('D:/companyProject/qrs/src/pages/home/shop/list.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'secondary-list',
            name: 'secondary-list',
            component: () => import('D:/companyProject/qrs/src/pages/home/shop/secondary-list.vue'),
            /* no children */
          },
  _definePage_default_9
  )
        ],
      }
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'profile',
        component: () => import('D:/companyProject/qrs/src/pages/profile/index.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
      {
        path: 'accountManage',
        /* internal name: '/profile/accountManage' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'accountManage',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/index.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: 'checkEmail',
            name: 'checkEmail',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/checkEmail.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
  _mergeRouteRecord(
          {
            path: 'delAccount',
            name: 'delAccount',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/delAccount.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: 'fillCode',
            name: 'fillCode',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/fillCode.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
  _mergeRouteRecord(
          {
            path: 'setFirstPhone',
            name: 'setFirstPhone',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/setFirstPhone.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
  _mergeRouteRecord(
          {
            path: 'setNewEmail',
            name: 'setNewEmail',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/setNewEmail.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
  _mergeRouteRecord(
          {
            path: 'updatePwd',
            name: 'updatePwd',
            component: () => import('D:/companyProject/qrs/src/pages/profile/accountManage/updatePwd.vue'),
            /* no children */
          },
  _definePage_default_17
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('D:/companyProject/qrs/src/pages/profile/accounts.vue'),
        /* no children */
      },
  _definePage_default_18
  ),
      {
        path: 'address',
        /* internal name: '/profile/address' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'address',
            component: () => import('D:/companyProject/qrs/src/pages/profile/address/index.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: 'addressEdit',
            component: () => import('D:/companyProject/qrs/src/pages/profile/address/edit.vue'),
            /* no children */
          },
  _definePage_default_20
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'country-list',
        name: 'countryList',
        component: () => import('D:/companyProject/qrs/src/pages/profile/country-list.vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'login',
        component: () => import('D:/companyProject/qrs/src/pages/profile/login.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
  _mergeRouteRecord(
      {
        path: 'login-submit',
        name: 'login-submit',
        component: () => import('D:/companyProject/qrs/src/pages/profile/login-submit.vue'),
        /* no children */
      },
  _definePage_default_23
  ),
      {
        path: 'order',
        /* internal name: '/profile/order' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'conments',
            name: 'conments-list',
            component: () => import('D:/companyProject/qrs/src/pages/profile/order/conments.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
  _mergeRouteRecord(
          {
            path: 'editeConments',
            name: 'conments-edit',
            component: () => import('D:/companyProject/qrs/src/pages/profile/order/editeConments.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'info',
            name: 'orderInfoDisplay',
            component: () => import('D:/companyProject/qrs/src/pages/profile/order/info.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
          {
            path: 'list',
            name: '/profile/order/list',
            component: () => import('D:/companyProject/qrs/src/pages/profile/order/list.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'register',
        name: 'register',
        component: () => import('D:/companyProject/qrs/src/pages/profile/register.vue'),
        /* no children */
      },
  _definePage_default_27
  ),
  _mergeRouteRecord(
      {
        path: 'rules',
        name: 'rules',
        component: () => import('D:/companyProject/qrs/src/pages/profile/rules.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
  _mergeRouteRecord(
      {
        path: 'rules1',
        name: 'rules1',
        component: () => import('D:/companyProject/qrs/src/pages/profile/rules1.vue'),
        /* no children */
      },
  _definePage_default_29
  ),
  _mergeRouteRecord(
      {
        path: 'settings',
        name: 'settings',
        component: () => import('D:/companyProject/qrs/src/pages/profile/settings.vue'),
        /* no children */
      },
  _definePage_default_30
  ),
      {
        path: 'userInfo',
        /* internal name: '/profile/userInfo' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'userInfo',
            component: () => import('D:/companyProject/qrs/src/pages/profile/userInfo/index.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: 'userInfoEdit',
            component: () => import('D:/companyProject/qrs/src/pages/profile/userInfo/edit.vue'),
            /* no children */
          },
  _definePage_default_32
  ),
  _mergeRouteRecord(
          {
            path: 'qrcode',
            name: 'qrcode',
            component: () => import('D:/companyProject/qrs/src/pages/profile/userInfo/qrcode.vue'),
            /* no children */
          },
  _definePage_default_33
  )
        ],
      }
    ],
  },
  {
    path: '/type',
    /* internal name: '/type' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'type',
        component: () => import('D:/companyProject/qrs/src/pages/type/index.vue'),
        /* no children */
      },
  _definePage_default_34
  )
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

