<script setup lang="ts">
definePage({
    name: 'shop-list',
    meta: {
        level: 2,
        title: 'shop-list',
        i18n: 'shop.title',
        rightText: 'shop.add',
        rightUrl: 'edit'
    },
})

const router = useRouter()
const toEdit = function (item: ShopListObj) {
    router.push(`secondary-list?title=${item.company}&id=${item.id}&sysTenantId=${item.sysTenantId}`)
}

const { t } = useI18n()
import { showToast } from 'vant';
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import type { ShopListObj } from '@/types/shop';
import { shopHistory } from '@/api/shop';
const userinfo: LoginData = getUserInfo()
let nuserId = ref<number>()
onMounted(() => {
    nuserId.value = userinfo.id
    getList()
})

let list = ref<ShopListObj[]>([])
const getList = async function () {
    const { code, data } = await shopHistory(nuserId.value)

    if (code == 200) {
        list.value = data
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
}
</script>

<template>
    <Container class="pl-0 pr-0">
        <div class="pl-4 pr-4 pt-3 pb-3 fz-3 fw-400" style="background-color: #fff;" v-for="(item, index) of list"
            :key="index">
            <div class="d-flex align-center justify-between" @click="() => toEdit(item)">
                <div>
                    <div class="mb-1 color-black fz-3 fw-400">
                        {{ item.company }}
                    </div>
                    <div class="mb-1 color-light-black fz-3 fw-400">
                        <span>{{ $t('shop.hotPhone') }}</span>&ensp;<span>{{ item.telephone }}</span>
                    </div>
                    <div class="mb-1 color-light-black fz-3 fw-400"><span>{{ $t('shop.addr') }}</span>&ensp;<span>{{ item.addr
                    }}</span></div>
                </div>
                <van-icon name="success" class="color-orange" v-if="item.isDefalut == 1"/>
            </div>
            <van-divider class="mt-2 mb-2" />
        </div>
    </Container>
</template>

<style lang="less" scoped>
:deep(.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon) {
    color: #fff;
}

:deep(.check .van-checkbox__icon--disabled .van-icon) {
    background-color: var(--color-orange);
    border-color: var(--color-orange);
}

:deep(.not-check .van-checkbox__icon--disabled .van-icon) {
    background-color: #fff;
}</style>
