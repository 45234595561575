<script setup lang="ts">
import CountryList from '@/pages/profile/country-list.vue';
import google from '@/assets/icon/google.png';
import facebook from '@/assets/icon/facebook.png';
import arrow_down_fill from '@/assets/icon/arrow_down_fill.png';
import { ref } from 'vue';
definePage({
  name: 'login',
  meta: {
    level: 2,
    title: 'login',
    i18n: 'profile.login',
  },
})
import { useRouter } from 'vue-router'
import type { Country } from '@/types/user';
const router = useRouter()

let value = ref<string>('');
let showArea = ref<boolean>(false)
onMounted(() => {
  value.value = (localStorage.getItem('loginAccount') as string) || ''
})

const inputAccount = function (e: any) {
  showArea.value = !isNaN(parseFloat(e)) && isFinite(e)
}

let areaObj = ref<any>({
  "id": 1,
  "country_name": "Nigeria",
  "country_code": "NG",
  "phone_code": "+234"
});
const toLogin = function () {
  router.push(`login-submit?loginAccount=${value.value}&areaCode=${JSON.stringify(areaObj.value)}`)
}
let show = ref<boolean>(false)
const dialogVisible = function(e: Country) {
  show.value = false
  areaObj.value = e
}
</script>

<template>
  <Container style="background-color: #fff;">
    <van-action-sheet v-model:show="show" :round="false">
      <CountryList @dialogVisible="dialogVisible"/>
    </van-action-sheet>
    <div class="van-cell van-field van-field--label-top mb-24">
      <div class="van-cell__title van-field__label van-field__label--top"><label id="van-field-4-label"
          for="van-field-4-input">{{ $t('login.account') }}</label></div>
      <div class="van-cell__value van-field__value input-container border-container">
        <div class="d-flex align-center" style="height: 100%;width: 100%;">
          <div class="d-flex align-center" @click="show = true">
            <div v-if="showArea">{{ areaObj.phone_code }}</div>
            <img v-if="showArea" :src="arrow_down_fill" class="ml-2 mr-4 img-16" />
          </div>
          <van-field class="account-input" ref="login-ref-input" style="flex: 1;" v-model="value" label-align="top" :clearable="true"
            clear-trigger="always" @update:model-value="inputAccount" />
        </div>
      </div>
    </div>

    <van-button type="warning" class="subject-btn" @click="toLogin">{{ $t('login.next') }}</van-button>

    <van-divider class="mb-0">{{ $t('login.otherLoginWay') }}</van-divider>
    <van-cell class="text-center fz-3 fw-400 color-lavender pt-0 mb-8" :title="$t('login.otherLogin')" to="register" />

    <VanButton class="other-btn" type="default" block>
      <VanRow style="width: 100%;" class="d-flex align-center">
        <VanCol span="12" class="text-end"><img :src="google" class="mr-24 img-28" /></VanCol>
        <VanCol span="12" class="text-start">{{ $t('login.login1') }}</VanCol>
      </VanRow>
    </VanButton>
    <VanButton class="other-btn" type="default" block>
      <VanRow style="width: 100%;" class="d-flex align-center">
        <VanCol span="12" class="text-end"><img :src="facebook" class="mr-24 img-28" /></VanCol>
        <VanCol span="12" class="text-start">{{ $t('login.login2') }}</VanCol>
      </VanRow>
    </VanButton>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell.account-input) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.login-label {
  font-size: min(3.733vw, 22.4px);
  font-weight: 500;
  color: #73767a;
  margin-bottom: var(--van-padding-base);
}

:deep(.van-cell__title.van-field__label.van-field__label--top label) {
  &:extend(.login-label);
}

:deep(.van-field__body) {
  width: 100%;
}

.input-container {
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 500
}

.border-container {
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 1);
}

:deep(.primary-input .van-cell__value.van-field__value) {
  &:extend(.input-container);
  &:extend(.border-container);
}

.subject-btn {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background: rgba(255, 74, 33, 1);
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-btn {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
  height: 46px;
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 1);
  margin-bottom: 12px;
}

:deep(.van-button__text) {
  display: flex;
  align-items: center;
  width: 100%;
}
:deep(.van-cell:after) {
  border-bottom: unset !important
}

:deep(.van-popup.van-popup--bottom.van-safe-area-bottom.van-action-sheet) {
  height: 100% !important;
  max-height: 100% !important;
}
</style>
