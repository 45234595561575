<script setup lang="ts">
definePage({
    name: 'shop-list-edit',
    meta: {
        level: 2,
        title: 'shop-list-edit',
        i18n: 'shop.title'
    },
})

import type { getShopInfoReq, ShopListObj, addShopReq } from '@/types/shop';
import { sure, addUserShop } from '@/api/shop';
const router = useRouter()

const { t } = useI18n()
import { showToast } from 'vant';
const formatter = (value: string) => value.trim()
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
let user: LoginData = getUserInfo()
let req = ref<getShopInfoReq>({
    code: '',
    nuserId: user.id,
    sysTenantId: null
});
let res = ref<ShopListObj>({
    id: 0,
    sysTenantId: 0,
    code: '',
    company: '',
    telephone: '',
    addr: '',
    isDefalut: 0
})
const addClick = async function () {
    const { code, data } = await sure(req.value)

    if (code == 200) {
        res.value = data
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
}
const onSubmit = async function () {
    const q: addShopReq = {
        nuserId: user.id,
        sysTenantId: res.value.sysTenantId
    }
    const { code } = await addUserShop(q)

    if (code == 200) {
        router.go(-1)
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
}
</script>

<template>
    <Container class="pl-0 pr-0">
        <van-form @submit="onSubmit">
            <van-cell-group inset class="mb-3">
                <van-field v-model="req.code" :label='$t("shop.shopId")' label-align="top" maxlength="5" type="number"
                    :formatter="formatter">
                    <template #button>
                        <van-button size="small" :disabled="!Boolean(req.code)" @click="addClick"
                            style="background-color: var(--color-orange);color: #fff;">{{
                                $t("shop.add") }}</van-button>
                    </template>
                </van-field>
                <van-field v-model="res.company" :label='$t("shop.company")' label-align="top" disabled />
                <van-field v-model="res.telephone" :label='$t("shop.phone")' label-align="top" disabled />
                <van-field v-model="res.addr" :label='$t("shop.addr")' label-align="top" disabled />
                <van-cell />
            </van-cell-group>
            <div class="ma-4" style="background-color: #fff;">
                <van-button class="btn-fill-orange" :disabled="!Boolean(res.sysTenantId)" style="width: 100%;"
                    native-type="submit">{{ $t('shop.save')
                    }}</van-button>
            </div>
        </van-form>
    </Container>
</template>
   
<style lang="less" scoped>
:deep(.van-cell-group.van-cell-group--inset) {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 0;
}
</style>
