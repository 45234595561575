<script setup lang="ts">
import { getOrderInfo, getUserInfo } from '@/storage/localStorage';
import type { ShopSendInfo, SubmitOrderReq, TaxList } from '@/types/goodsCar';
import GoodsImg from "@/pages/goods/img.vue";
import { showToast } from "vant";
import { getDeliveryMsg, getOneOrder, queryGoodsCommentList, updateOrder } from '@/api/settings';
import type { CancelOrderReq, CommentListReq } from '@/types/settings';
import note from '@/assets/icon/note.png';

definePage({
  name: "orderInfoDisplay",
  meta: {
    level: 2,
    title: "orderInfoDisplay",
    i18n: "countryList.title",
  },
});

const router = useRouter();
const onBack = function () {
  history.back()
};

let info = ref<SubmitOrderReq>(getOrderInfo());
const taxMap = ref<Map<number, TaxList>>(new Map());
onMounted(()=>{
    getOne()
    getSendCost()
    for (let item of info.value.nordersDetailList) {
    let amount = item.amount
    for (let batch of item.batchingList) {
      amount += batch.amount
    }
    item.showPrice = amount

    for (let t of item.taxList) {
      if (taxMap.value.has(t.setTaxId)) {
        const tempItem: TaxList = taxMap.value.get(t.setTaxId);
        tempItem.amount += t.amount;
        taxMap.value.set(t.setTaxId, tempItem);
      } else {
        taxMap.value.set(t.setTaxId, t);
      }
    }
  }
})

let shopSendRes = ref<ShopSendInfo>({
    id: 0,
    name: '',
    telephone: '',
    address: '',
    memo: '',
    deliveryUserName: '',
    deliveryUserTelephone: '',
    nordersId: 0
});
const getSendCost = async () => {
  const { code, data } = await getDeliveryMsg(+route.query.id);
  if (code == 200) {
    if (data) {
      shopSendRes.value = data;
    }
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const goodsTotalAmount = ref<number>(0)
const route = useRoute()
const getOne = async () => {
  const { code, data } = await getOneOrder(+route.query.id);
  if (code == 200) {
    info.value = data
    for (let item of info.value.nordersDetailList) {
        for (let b of item.batchingList) {
            item.amount += b.amount
        }
        goodsTotalAmount.value += item.amount
    }
    if (info.value.isDelivery === 2) {
      nextTick(()=>{
        getQrCode()
      })
    }
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const { t } = useI18n();
let btnState = ref<boolean>(false);

import moment from 'moment' //导入模块
moment.locale('zh-cn')

const $moment = moment
const showTips = ref<boolean>(false)
const readyCancel = function() {
  showTips.value = true
}
import {Countdown} from 'vue3-flip-countdown'

const toPay = function(e) {
  e.stopPropagation();
  router.push('/car/selectPayMethod')
}

import { getShopInfo } from '@/storage/localStorage';
import type { ShopInfoRes } from '@/types/shop';
const shop: ShopInfoRes = getShopInfo()

const cancelOrder = async (type: number) => {
  if (btnState.value) return;
  btnState.value = true;

  const param: CancelOrderReq = {
    nordersId: +route.query.id,
    nuserId: getUserInfo().id,
    operation: type
  }
  const { code } = await updateOrder(param);
  if (code == 200) {
    btnState.value = false;
    showTips.value = false
    history.back()
    return;
  }
  btnState.value = false;
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
}

const showRecive = ref<boolean>(false)
const toComment = function(item) {
  getList(item, 1)
}

import { ref, onMounted } from 'vue';
import QRCode from 'qrcode';
import type { LoginData } from '@/types/user';
 
const qrCanvas = ref(null);
const getQrCode = ()=> {
  const text = `{"nordersId":${+route.query.id},"code":${info.value.code}}`;
  QRCode.toCanvas(qrCanvas.value, text, error => {
    if (error) console.error(error);
  });
}

let userinfo: LoginData = getUserInfo()
const getList = async (select, isComment) => {
  let listReq: CommentListReq = {
    isComment: isComment,
    isDelivery: +route.query.isDelivery || 1,
    nuserId: userinfo.id,
    pageNum: 1,
    pageSize: 9999999,
    shopId: shop.id,
    sysTenantId: shop.sysTenantId,
    type: 5
  }
  const { code, data } = await queryGoodsCommentList(listReq)
  if (code == 200) {
    let temp = null
    for (let item of data) {
      if (item.nordersId == select.nordersId && item.goodsSkuId == select.goodsSkuId) {
        temp = item
        router.push(`/profile/order/editeConments?data=${JSON.stringify(temp)}`)
        break
      }
    }
    if (temp == null) {
      getList(select, 2)
    }
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}
</script>

<template>
  <Container class="pl-0 pr-0 flex-column container-body" flex justify-between>
    <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="text-center dialog-title mb-6">{{ $t('orderList.confirm') }}</div>
        <div class="dialog-info mb-6">{{ $t('orderList.delAccount2Info') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showTips = false">{{ $t('orderList.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="()=>cancelOrder(2)" :disabled="btnState">{{ $t('orderList.confirm') }}</van-button>
        </div>
      </div>
    </van-dialog>

    <van-dialog width="327px" v-model:show="showRecive" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="text-center dialog-title mb-6">{{ $t('orderInfo.receive') }}</div>
        <div class="dialog-info mb-6">{{ $t('orderInfo.reciveInfo') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showRecive = false">{{ $t('orderInfo.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="()=>cancelOrder(5)">{{ $t('orderInfo.sure') }}</van-button>
        </div>
      </div>
    </van-dialog>

    <VanNavBar :title='$t("orderList.code") + "：" + info.code' :fixed="true" clickable left-arrow @click-left="onBack">
      <template #left>
        <van-icon name="cross" size="22" />
      </template>
    </VanNavBar>
    <div style="flex: 1" overflow-auto>
      <van-row
        mx-8
        my-16
        py-12
        px-16
        style="background-color: #fff"
        flex
        class="align-center"
        v-if="![4,5].includes(info.bstatus) && info.isDelivery === 1"
      >
        <van-col span="12" text-16 fw-500 class="color-orange">
            <div>{{$t("orderInfo.paying")}}{{ info.totalAmount }}</div>
            <div>{{$moment.unix(info.updateTime).format("YYYY-MM-DD HH:mm")}}</div>
        </van-col>
        <van-col span="12" class="d-flex" justify-end>
            <van-button v-if="info.status == 2" class="btn-fill-grey" min-h-36>{{ $t('orderList.canceled') }}</van-button>
            <div v-if="info.status == 1 && info.payStatus == 1">
                <van-button v-if="(new Date().getTime() / 1000 - info.updateTime) / 60 <= 15" @click="toPay" class="btn-fill-orange payBtn" min-h-36>
                    <span mr-4>{{ $t('orderList.toPey') }}</span>
                    <Countdown 
                    :deadline='$moment.unix(info.updateTime).add(15, "m").format("YYYY-MM-DD HH:mm:ss")'
                    :flipAnimation="false" :showLabels="false" :showDays="false" :showHours="false"
                    />
                </van-button>
                <van-button v-else class="btn-fill-grey" min-h-36>{{ $t('orderList.timeOut') }}</van-button>
            </div>
        </van-col>
      </van-row>

      <van-row
        mx-8
        my-16
        py-12
        px-16
        style="background-color: #fff"
        flex
        class="align-center"
        v-if="info.isDelivery === 2"
      >
        <van-col span="24" class="d-flex color-orange" justify-center text-16 fw-500>
            {{ info.code }}
        </van-col>
        <van-col span="24" class="d-flex" justify-center>
          <div>
            <canvas ref="qrCanvas"></canvas>
          </div>
        </van-col>
      </van-row>

      <van-row
        mx-8
        mt-16
        mb-4
        pa-16
        style="background-color: #fff"
        flex
        class="align-center"
        v-if="info.isDelivery === 1"
      >
        <van-col span="24" text-16 fw-500 mb-14 class="color-black">{{
          $t("orderInfo.senTitle")
        }}</van-col>
        <van-col span="24" text-12 fw-400 mb-8>
            <span class="color-light-black">{{[4,5].includes(info.bstatus) ? $t("orderInfo.getMan") : $t("orderInfo.senMan")}}</span>
            <span class="color-black"></span>
        </van-col>
        <van-col span="24" text-12 fw-400 mb-8>
            <span class="color-light-black">{{$t("orderInfo.senName")}}：</span>
            <span class="color-black">{{shopSendRes.name}}</span>
        </van-col>
        <van-col span="24" text-12 fw-400 mb-8>
            <span class="color-light-black">{{$t("orderInfo.senPhone")}}：</span>
            <span class="color-black">{{shopSendRes.telephone}}</span>
        </van-col>
        <van-col span="24" text-12 fw-400 mb-8>
            <span class="color-light-black">{{$t("orderInfo.sendAddr")}}：</span>
            <span class="color-black">{{shopSendRes.address}}</span>
        </van-col>

        <van-col span="24" text-12 fw-400 mb-8 pt-8 v-if="info.bstatus == 4" class="boder-top-line">
            <span class="color-light-black">{{$t("orderInfo.senMan")}}：</span>
            <span class="color-black">{{shopSendRes.deliveryUserName}}</span>
        </van-col>
        <van-col class="d-flex" span="24" text-12 fw-400 mb-8 justify-between v-if="info.bstatus == 4">
            <div>
                <span class="color-light-black">{{$t("orderInfo.senPhone")}}：</span>
                <span class="color-black">{{shopSendRes.deliveryUserTelephone}}</span>
            </div>
            <van-icon name="phone-o" mr-4 color="orange"/>
        </van-col>
      </van-row>
      <van-row
        mx-8
        mt-16
        mb-4
        pa-16
        style="background-color: #fff"
        flex
        class="align-center"
      >
        <van-col span="24" text-16 fw-500 mb-14 class="color-black">{{
          shop.name
        }}</van-col>
        <van-col span="24" text-12 fw-400 mb-8 class="color-light-black">
            <van-icon name="phone-o" mr-4/>
            <span>{{shop.telephone}}</span>
        </van-col>
        <van-col span="24" text-12 fw-400 mb-8 class="color-light-black">
            <van-icon name="location-o" mr-4/>
            <span>{{shop.addr}}</span>
        </van-col>
      </van-row>
      <van-row class="goods-body" flex-1 mx-8 mt-12 boder-bottom-line>
        <van-col span="24" text-16 fw-500 px-16 pt-16 class="color-black" bg-white>
            {{ $t("orderInfo.goodsInfo") }}
        </van-col>
        <van-col
          text-12
          fw-400
          color-black
          pa-16
          span="24"
          justify-between
          class="d-flex align-center boder-bottom-line"
          v-for="(item, index) of info.nordersDetailList"
          :key="index"
          flex
          style="min-height: 93px; min-width: 0;background-color: #fff"
        >
          <div flex class="align-center" style="min-width: 0; width: 100%">
            <div class="img-60" mr-12>
              <GoodsImg
                :showType="item.showType"
                :color="item.color"
                :picUrl="item.picUrl"
              />
            </div>
            <div
              class="flex-column content-item"
              flex-1
              flex
              justify-between
              style="min-width: 0"
            >
              <div flex justify-between class="align-center">
                <div class="ellipsis-space" style="width: 70%">
                  {{ item.goodsName
                  }}{{ item.type === 2 ? `(${item.goodsSkuName})` : "" }}
                </div>
                <div fw-500 text-end style="width: 30%">{{ item.amount }}</div>
              </div>
              <div class="color-light-black ellipsis-space">
                <span mr-4 :style="`opacity: ${item.memo ? 1 : 0}`">+</span>
                <span>{{ item.memo }}</span>
              </div>
              <div flex justify-between class="align-center">
                <div>
                    <span>{{ item.totalNum }}</span>
                    <span> x </span>
                    <span v-if="item.batchingList.length > 0">
                    ( {{ item.price }} + {{item.batchingList.map(v=>v.amount).join(' + ')}} )
                    </span>
                    <span v-else>{{ item.price }}</span>
                </div>
                <div>
                    <img :src="note" w-14 h-14 @click="()=>toComment(item)" v-if="info.bstatus === 5"/>
                </div>
              </div>
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row px-16 mx-8 class="cost-row"
        style="background-color: #fff">
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
        >
          <div fw-400 class="color-light-black">{{ $t("pay.goodsAmount") }}</div>
          <div fw-500>{{ goodsTotalAmount }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
          v-if="info.isDelivery === 1"
        >
          <div fw-400 class="color-light-black">{{ $t("pay.sendMoney") }}</div>
          <div fw-500>{{ info.deliveryAmount }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
          v-for="map in taxMap.values()"
        >
          <div fw-400 class="color-light-black">{{ map.name }}</div>
          <div fw-500>{{ +map.amount.toFixed(2) }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-end
          class="d-flex align-center"
        >
          <div text-14 fw-400 mr-8>{{ $t("orderInfo.userPay") }}</div>
          <div text-24 fw-500 style="color: rgb(235, 57, 18)">{{ info.totalAmount }}</div>
        </van-col>
      </van-row>
    </div>
    <div v-if="info.bstatus === 5 && info.isDelivery === 2 && info.status === 1 && info.payStatus === 3"></div>
    <div
      class="bottom align-center"
      flex
      py-12
      px-16
      style="background-color: #fff"
      v-else
    >
      <div class="btn-outer" ml-8 v-if="!info.bstatus || info.bstatus === 3">
        <van-button v-if="info.status == 2" class="btn-outline-orange">{{ $t('orderList.canceled') }}</van-button>
        <van-button v-else class="btn-outline-orange" @click="readyCancel" :disabled="btnState">{{ $t('orderInfo.cancelOrder') }}</van-button>
      </div>
      <div v-if="info.bstatus == 4" w-full>
        <div flex justify-between w-full v-if="info.status === 1">
          <van-button class="btn-outline-orange" @click="readyCancel" :disabled="btnState">{{ $t('pay.cancelBtn') }}</van-button>
          <van-button class="btn-fill-orange" @click="showRecive=true">{{ $t('orderInfo.receive') }}</van-button>
        </div>
        <van-button v-else class="btn-outline-orange" style="width: 100%;">{{ $t('orderList.canceled') }}</van-button>
      </div>
      <div class="btn-outer" ml-8 v-else-if="info.bstatus == 5">
        <van-button class="btn-outline-orange">{{ $t('orderInfo.reback') }}</van-button>
      </div>
    </div>
  </Container>
</template>

<style lang="less" scoped>
.container-body {
  height: 100vh;
}

.img-60 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow-x: hidden;
}

.bottom {
  height: 70px;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.07);
  z-index: 2;
}

.btn-outer {
  width: 100%;
  .btn-outline-orange {
    width: 100% !important;
  }
}

.cost-row .van-col {
  height: 55px;
}

.content-item {
  height: 60px;
}

.goods-body {
  overflow: auto;
}

.goods-title {
  height: 56px;
}

.btn-next {
  .btn-fill-orange {
    width: 100%;
  }
}
:deep(.flip-clock__piece:not(:last-child) .no-animation__card::after) {
  content: ":";
}
:deep(.no-animation__card) {
  font-size: 16px !important;
  font-weight: 500;
  color: #fff !important;
}
:deep(.van-button__text) {
  display: flex;
  align-items: center;
}

.payBtn {
  width: auto;
}
</style>
