<script setup lang="ts">
definePage({
  name: 'address',
  meta: {
    level: 2,
    title: 'address',
    i18n: 'address.title',
    rightText: 'address.add',
    rightUrl: 'address/edit'
  },
})

const router = useRouter()
const toEdit = function(id) {
  router.push(`/profile/address/edit?id=${id}`)
}

import deleteIcon from '@/assets/icon/delete.png';
const { t } = useI18n()
import { showToast } from 'vant';
import {getUserInfo} from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import type { AddressListObj } from '@/types/settings';
import {getAddressList, setDefault} from '@/api/settings';
const userinfo: LoginData = getUserInfo()
let nuserId = ref<number>()
onMounted(() => {
  nuserId.value = userinfo.id
  getList()
})

let list = ref<AddressListObj[]>([])
const getList = async function() {
  const { code, data } = await getAddressList(nuserId.value)

  if (code == 200) {
    list.value = data
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
}

const clickDefault = async function(select: AddressListObj) {
  const { code } = await setDefault(select.id, nuserId.value)
  if (code == 200) {
    getList()
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
}
</script>

<template>
  <Container class="pl-0 pr-0">
    <div class="mb-4 pl-4 pr-4 pt-3 pb-3 fz-3 fw-400" style="background-color: #fff;" v-for="(item, index) of list" :key="index">
      <div class="d-flex align-center justify-between" @click="()=>toEdit(item.id)">
        <div>
          <div class="mb-1 color-black">
            {{ item.firstName + item.lastName }}
          </div>
          <div class="mb-1 color-light-black">
            <span>{{ $t('address.hotPhone') }}</span>&ensp;<span>{{ item.telephone }}</span>
          </div>
          <div class="mb-1 color-light-black"><span>{{ $t('address.addr') }}</span>&ensp;<span>{{ item.address }}</span></div>
        </div>
        <van-icon name="arrow"/>
      </div>
      <van-divider class="mt-2 mb-2" />
      <div class="d-flex align-center justify-between">
        <van-radio-group v-model="item.isDefault" @click="()=>clickDefault(item)">
          <van-radio :name="1" icon-size="24px">{{ $t('address.default') }}</van-radio>
        </van-radio-group>
        <img :src="deleteIcon" class="img-20"/>
      </div>
    </div>
  </Container>
</template>

<style lang="less" scoped>
</style>
