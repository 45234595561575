<script setup lang="ts">
definePage({
  name: 'type',
  meta: {
    level: 1,
  },
})

import car_default from '@/assets/icon/car_default.png';
import CarIcon from '@/pages/goods/car.vue';
const searchCounryList = function () {

}
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const userinfo: LoginData = getUserInfo()
let nuserId = null
import { getShopId, hasToken } from '@/storage/localStorage';
import { typeList } from '@/api/home';
import { typeGoodstList } from '@/api/type';
onMounted(() => {
  if (hasToken()) {
    nuserId = userinfo.id
    getTyps()
  }
})

let number = ref<number>()
import type { CarListRes } from '@/types/goodsCar';
const list = ref<CarListRes[]>([])
const getAdList = async () => {
  const { code, data } = await getCarList(shopId, nuserId)
  if (code == 200) {
    list.value = data
    number.value = 0
    let map = {}
    for (let item of list.value) {
      number.value += item.num
      if (item.goodsId in map) {
        map[item.goodsId] += item.num
      } else {
        map[item.goodsId] = item.num
      }
    }
    for (let obj of goods.value) {
      obj.badgeNum = 0
      for (let key in map) {
        if (+obj.goodsId === +key) {
          obj.badgeNum = map[key]
        }
      }
    }
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const { t } = useI18n()
import { showToast } from 'vant';
import type { HomeGoodsInfo, PageObj } from '@/types/home';
let categories = ref<TypeGoodsRes[]>([])
const shopId = getShopId()
const route = useRoute()
const getTyps = async function () {
  const { code, data } = await typeList(shopId)

  if (code == 200) {
    categories.value = []
    let temps = data
    if (temps && temps.length > 0) {
      for (let item of temps) {
        await getGoods(item)
      }
      for (let type of temps) {
        if (type.items && type.items.length > 0) {
          categories.value.push(type)
        }
      }
      await getAdList()
      nextTick(() => {
        initializeRoll()
        initializeOwn()
        if (route.query.id) {
          const index = categories.value.findIndex(v=>v.id === +route.query.id)
          onClickNav(index)
        }
      })
    }
    return
  }
  categories.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const page: PageObj = {
  pageNum: 1,
  pageSize: 999
}
let goods = ref<HomeGoodsInfo[]>([])
const getGoods = async function (obj: TypeGoodsRes) {
  const req: TypeReq = {
    shopId: shopId,
    typeId: obj.id
  }
  const { code, data } = await typeGoodstList(req, page)

  if (code == 200) {
    obj.items = data.list
    goods.value = data.list
    return
  }
  goods.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

import { getColor } from "@/utils/colorUtil";
import type { TypeGoodsObj, TypeGoodsRes, TypeReq } from '@/types/type';
import { getCarList } from '@/api/goodsCar';
const getBgColor = function (color) {
  const _color = getColor(color)
  return _color.val
}

const router = useRouter()
const toGoodsInfo = function (g: TypeGoodsObj) {
  router.push(`/home/goods/info?goodsId=${g.goodsId}&nGoodsId=${g.ngoodsId}&type=${g.type}`)
}

const closeCar = function () {
  getAdList()
}

const toCarList = function () {
  router.push("/car")
}

import BScroll from "better-scroll"; //引入BScroll插件
let scrollY = ref<number>(0) // 右侧滑动的时候距离顶部的值
let rightSide = ref<any[]>([]) // 所有右侧分类li的top组成的数组  (列表第一次显示后就不再变化)
const currentIndex = computed(() => {
  //findIndex() 方法返回传入一个测试条件（函数）符合条件的数组第一个元素位置
  //获取数组rightSide中每一个下标值并筛选条件返回第一个元素索引位置
  const index = rightSide.value.findIndex((top, index) => {
    // scrollY>=当前top && scrollY<下一个top
    return scrollY.value >= top && scrollY.value < rightSide.value[index + 1];
  });
  // 返回结果
  return index;
})
let foodsScroll = null
// 初始化滚动
const initializeRoll = () => {
  // new一个类的实例，前提必须确保父元素和子元素的内容已经正确渲染了，不然不会生效
  new BScroll(".leftNav", {
    click: true
  });
  // 组件实例上挂属性
  foodsScroll = new BScroll(".rightContant", {
    probeType: 2, // 因为惯性滑动不会触发
    click: true
  });

  // 给右侧列表绑定scroll监听
  foodsScroll.on("scroll", ({
    x,
    y
  }) => {
    scrollY.value = Math.abs(y);
    x
  });
  // 给右侧列表绑定scroll结束的监听
  foodsScroll.on("scrollEnd", ({
    x,
    y
  }) => {
    console.log("操作结束的下标值" + x, y);
    scrollY.value = Math.abs(y);
  });
}

const shopContant = ref(null)
// 初始化rightSide
const initializeOwn = () => {
  // 初始化rightSide
  const _rightSide = [];
  let top = 0;
  _rightSide.push(top);
  // 找到所有分类下的li
  const lis = shopContant.value.getElementsByClassName("eventList"); //获取dom元素lii（eventList），对其进行操作
  // slice() 方法返回一个新的数组对象
  Array.prototype.slice.call(lis).forEach(li => {
    top += li.clientHeight; //相当于top = top + li.clientHeight
    _rightSide.push(top);
  });

  //更新数据
  rightSide.value = _rightSide;
  console.log("每一个分类的下标" + rightSide.value);
}
//左侧点击事件
const onClickNav = (index) => {
  //拿到目标位置的scrollY，点击左侧导航，右侧列表滑动到对应的位置
  const _scrollY = rightSide.value[index];
  // 点击的分类成为当前分类，索引当前选中的导航标题
  scrollY.value = _scrollY;
  // 平滑滑动右侧列表
  foodsScroll.scrollTo(0, -scrollY.value, 1000);
}
</script>

<template>
  <Container :padding-x="0" :padding-t="0" style="background-color: #fff;">
    <div class="align-center" flex px-16 py-8>
      <van-search flex-1 @input="searchCounryList" background="unset" :placeholder="$t('home.search')" shape="round"
        :clearable="false" />
      <van-badge :content="number" max="99" :show-zero="false" ml-16>
        <img :src="car_default" alt="" width="24" height="24" @click="toCarList">
      </van-badge>
    </div>
    <div class="commodity" flex position-fixed top-0 w-full bg-white overflow-hidden>
      <!--// 左侧导航内容 -->
      <div class="leftNav" w-80 min-w-80>
        <ul>
          <li class="left-li" v-for="(type, index) in categories" :key="index" :class="{ typeStyle: index === currentIndex }"
            @click="onClickNav(index)">
            <span flex min-h-40 justify-center text-12 pa-4 class="align-center">{{ type.name }}</span>
          </li>
        </ul>
      </div>
      <!--// 右侧商品内容 -->
      <div class="rightContant">
        <ul ref="shopContant">
          <li class="eventList" v-for="(category, index) in categories" :key="index">
            <div class="rightTtitle ellipsis-space" pl-12 h-26 line-height-26 text-12 ma-0>{{ category.name }}</div>
            <ul>
              <li flex ma-16 pb-16 class="align-center boder-bottom-line" v-for="(good, index) in category.items" :key="index">
                <div class="goods-item-img" @click="toGoodsInfo(good)">
                  <div v-if="good.showType == 1" :style="`background-color: ${getBgColor(good.color)};`"></div>
                  <div w-82 h-82 v-else>
                    <img w-full h-full :src="good.picUrl" />
                  </div>
                </div>
                <div pl-6 min-w-0 flex flex-col flex-1 h-82 justify-between>
                  <div @click="toGoodsInfo(good)">
                    <div text-14 fw-600 class="ellipsis-space">{{ good.goodsName }}</div>
                    <div text-12 fw-500 class="ellipsis-space">{{ good.memo }}</div>
                  </div>
                  <div flex justify-between>
                    <div class="flex align-center" @click="toGoodsInfo(good)" style="flex: 1;">
                      <span text-16 fw-600 class="color-orange">{{ good.price }}</span>
                      <span text-12 fw-600 style="color: rgb(178, 178, 178);">/{{ $t('type.unit') }}</span>
                    </div>
                    <CarIcon @close="closeCar" :badgeNum="good.badgeNum" :type="good.type" :goodsId="good.goodsId"
                      :nGoodsId="good.ngoodsId" :nUserId="nuserId" :shopId="shopId" />
                  </div>
                </div>
              </li>
              <div h-1></div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </Container>
</template>
<style lang="less" scoped>
:deep(.van-search) {
  padding: 8px 0
}

.goods-item-img {
  height: 100%;
  width: 88px;

  div {
    height: 82px;
    width: 82px;
  }
}

:deep(.van-sidebar-item--select) {
  color: var(--color-orange);
}

.commodity {
  bottom: min(13.333vw, 80px);
}

.leftNav {
  background: rgb(248, 248, 248);
}

.typeStyle {
  /* 左侧导航选中的样式 */
  background: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--color-orange);
  justify-content: center;
  border-left: 2px solid var(--color-orange);
}

.left-li {
  border-left: 2px solid rgb(248, 248, 248);
}

.rightContant {
  /* 右侧大盒子的整体样式 */
  width: calc(100% - 80px);
}

.rightTtitle {
  font-weight: bold;
  color: rgb(147, 153, 159);
  background: rgb(248, 248, 248);
}

:last-child {
  /* 点击左侧导航右侧内容与左边对齐 */
  margin-bottom: 0;
}
</style>