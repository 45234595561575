<script setup lang="ts">
definePage({
  name: 'settings',
  meta: {
    level: 2,
    title: 'settings',
    i18n: 'settings.title',
  },
})
import langs from '@/country/lang';
import { locale } from '@/utils/i18n'
import countrys from "@/country";
let countryList = ref<Array<Country>>(countrys.cn[locale.value])
let areaObj = ref<Country>();
import share from '@/assets/icon/share.png';
import {hasToken, getUserInfo, removeToken, removeUserInfo, setUserInfo} from '@/storage/localStorage';
const { t } = useI18n()
let settingDatas = ref<Array<any>>([])
const isLogin = hasToken()
import type {Country, LoginData} from '@/types/user';
onMounted(()=>{
  if (isLogin) {
    settingDatas.value = [
      { id: 0, className: 'mb-3', title: '', value: '', icon: 'qr', url: 'userInfo' },
      { id: 1, className: '', title: t("settings.name1"), value: '', url: 'address' },
      { id: 2, className: 'mb-3', title: t("settings.name2"), value: '', url: 'accountManage' },
      { id: 3, className: '', title: t("settings.name3"), value: '', hideLink: true, img: share, type: 1 },
      { id: 4, className: '', title: t("settings.name4"), value: '', type: 2 },
      { id: 5, className: '', title: t("settings.name5"), value: '', type: 3 },
      { id: 6, className: 'mb-3', title: t("settings.name6"), value: '', type: 4 },
      { id: 7, className: '', title: t("settings.name7"), value: '', url: 'rules' },
      { id: 8, className: '', title: t("settings.name8"), value: '', url: 'rules1' },
    ]
    const user: LoginData = getUserInfo()
    settingDatas.value[0].title = user.name || user.email
    if (user.sysCountryId && user.sysCountryId > 0) {
      areaObj.value = countryList.value.find(v=>v.id == user.sysCountryId)
      setPosition()
    }
    if (user.sysLanguageId && user.sysLanguageId > 0) {
      let temp = langs.find(v=>v.sysLanguageId == user.sysLanguageId)
      setLang(temp)
      lang.value = temp
    }
    setMoney(money.value.code)
  } else {
    settingDatas.value = [
      { id: 1, className: '', title: t("settings.name1"), value: '', url: 'address' },
      { id: 2, className: 'mb-3', title: t("settings.name2"), value: '', url: 'accountManage' },
      { id: 3, className: '', title: t("settings.name3"), value: '', hideLink: true, img: share, type: 1 },
      { id: 5, className: '', title: t("settings.name5"), value: '', type: 3 },
      { id: 6, className: 'mb-3', title: t("settings.name6"), value: '', type: 4 },
      { id: 7, className: '', title: t("settings.name7"), value: '', url: 'rules' },
      { id: 8, className: '', title: t("settings.name8"), value: '', url: 'rules1' },
    ]
  }
})

const router = useRouter()
const toNextPage = function(url, type) {
  if (url) {
    router.push(url)
    return
  }
  switch (type) {
    case 1:
      show.value = true
      break;
    case 2:
      show2.value = true
      break;
    case 3:
      show3.value = true
      break;
    case 4:
      show4.value = true
      break;
    default:
      break;
  }
}

const showTips = ref<boolean>(false)
const logout = function() {
  removeToken()
  removeUserInfo()
  router.push(`accounts`)
}
const show = ref<boolean>(false)
const down = 'https://download.toposfy.com/devpackage/toposify_marchand.apk'

const show2 = ref<boolean>(false)
import CountryList from '@/pages/profile/country-list.vue';
import { showToast } from 'vant';
let user: LoginData = getUserInfo()
import { updateAccount } from '@/api/settings'
const dialogVisible = async function(e: Country) {
  if (!e) {
    show2.value = false
    return
  }
  if (!isLogin) {
    show2.value = false
    return
  }
  const param = reactive<any>({
    id: user.id,
    sysCountryId: e.id,
  });
  areaObj.value = e
  const { code } = await updateAccount(param)
  if (code == 200) {
    show2.value = false
    user.sysCountryId = param.sysCountryId
    setPosition()
    setUserInfo(user)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const setPosition = function() {
  settingDatas.value[4].value = areaObj.value.country_name
}

const setLang = function(e: langObj) {
  settingDatas.value[5].value = e.sysLanguageName
}

const show3 = ref<boolean>(false)
import LangList from '@/components/lang-list.vue';
import type { langObj, moneyObj } from '@/types/settings';
const lang = ref<langObj>({
    "sysLanguageId": 7,
    "sysLanguageCode": "en_GB",
    "sysLanguageName": "English"
})
const dialogVisibleLang = async function(e: langObj) {
  if (!e) {
    show3.value = false
    return
  }
  lang.value = e
  if (!isLogin) {
    show3.value = false
    setLang(e)
    locale.value = e.sysLanguageName
    return
  }
  const param = reactive<any>({
    id: user.id,
    sysLanguageId: e.sysLanguageId,
  });
  
  const { code } = await updateAccount(param)
  if (code == 200) {
    show3.value = false
    user.sysLanguageId = param.sysLanguageId
    setLang(e)
    locale.value = e.sysLanguageName
    setUserInfo(user)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

import MoneyList from '@/components/money-list.vue';
const show4 = ref<boolean>(false)
const money = ref<moneyObj>({
    "currencyId": 94,
    "code": "USD",
    "name": "美元 (USD)",
    "symbol": "$",
    "code1": "USD $"
})
const dialogVisibleMoney = function(e: moneyObj) {
  money.value = e
  setMoney(e.code) 
  show4.value = false
}
const setMoney = function(e: string) {
  settingDatas.value[6].value = e
}
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="text-center dialog-title mb-6">{{ $t('settings.delAccount2') }}</div>
        <div class="dialog-info mb-6">{{ $t('settings.delAccount2Info') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showTips = false">{{ $t('settings.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="logout">{{ $t('settings.confirm') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <van-action-sheet v-model:show="show" :title="$t('settings.link')">
      <div class="pl-2 pr-2 pt-2 pb-6">
        <a style="word-wrap: break-word;" :href="down" target=“_blank”>{{ down }}</a>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model:show="show2" :round="false">
      <CountryList @dialogVisible="dialogVisible" :showAreaCode="false"/>
    </van-action-sheet>
    <van-action-sheet v-model:show="show3" :round="false">
      <LangList v-model="lang" @dialogVisible="dialogVisibleLang" :showAreaCode="false"/>
    </van-action-sheet>
    <van-action-sheet v-model:show="show4" :round="false">
      <MoneyList v-model="money" @dialogVisible="dialogVisibleMoney" :showAreaCode="false"/>
    </van-action-sheet>
    <van-list>
      <van-cell class="list-cell pl-4 pr-4" :class="item.className" v-for="item in settingDatas" :key="item.id" :title="item.title"
        :value="item.value" :is-link="!item.hideLink" @click="toNextPage(item.url, item.type)">
        <template #value v-if="item.icon">
          <van-icon :name="item.icon" />
        </template>
        <template #right-icon v-if="item.hideLink">
          <img :src="item.img" class="img-18" />
        </template>
      </van-cell>
    </van-list>
    <div v-if="isLogin" class="pl-4 pr-4" style="width: 100%;background: var(--van-cell-background);">
      <van-button class="btn-outline-orange mt-9" style="width: 100%;" @click="showTips=true">{{ $t('settings.logout') }}</van-button>
    </div>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell .van-cell__title) {
  flex: unset;
  width: 70%;
}

:deep(.van-cell .van-cell__value) {
  flex: unset;
  width: 30%;
}

:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}

:deep(.van-popup.van-popup--bottom.van-safe-area-bottom.van-action-sheet) {
    height: 100% !important;
    max-height: 100% !important;
}
</style>
