<script setup lang="ts">
definePage({
    name: 'updatePwd',
    meta: {
        level: 2,
        title: 'updatePwd',
        i18n: 'pwd.title',
    },
})

let showPwd1 = ref<boolean>(false);
let showPwd2 = ref<boolean>(false);
let showPwd3 = ref<boolean>(false);

let check1 = ref<boolean>(false)
let check2 = ref<boolean>(false)
let check3 = ref<boolean>(false)
const inputPwd = function () {
  if (param.value.pwd == '') {
    check1.value = false
    check2.value = false
    check3.value = false
    return
  }
  check1.value = param.value.pwd.length >= 8 && param.value.pwd.length <= 16
  check2.value = /[A-Za-z]+/.test(param.value.pwd)
  check3.value = /[0-9]+/.test(param.value.pwd)
}

const { t } = useI18n()
import { showToast } from 'vant';

import type { LoginData } from '@/types/user';
import { getUserInfo } from '@/storage/localStorage';
let user: LoginData = getUserInfo()
import { updatePwd } from '@/api/settings';
import type { UpdatePwdReq } from '@/types/settings';
let param = ref<UpdatePwdReq>({
    content: '',
    email: user.email,
    id: user.id,
    oldPwd: '',
    pwd: '',
    rePwd: ''
})
let btnState = ref<boolean>(false)
const router = useRouter()
const onSubmit = async (_values: any) => {
    if (param.value.pwd !== param.value.rePwd) {
        showToast({
            message: t('pwd.pwdisDiff'),
            position: 'bottom',
        });
        return
    }
    if (btnState.value) return
    btnState.value = true
    const { code } = await updatePwd(param.value)
    if (code == 200) {
        router.push(`/profile/accounts`)
        showToast({
            message: t('pwd.updateInfo'),
            position: 'bottom',
        });
        btnState.value = false
        return
    }
    btnState.value = false
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
};
</script>

<template>
    <Container class="pl-0 pr-0" style="background-color: #fff;">
        <van-form class="mt-4" @submit="onSubmit" style="width: 100%;">
            <van-cell-group inset>
                <van-field class="primary-input" v-model="param.oldPwd" :type="showPwd1 ? 'text' : 'password'"
                :label="$t('pwd.pwd1')" label-align="top" :clearable="true" clear-trigger="always"
                :rules="[{ required: true }]" :right-icon="showPwd1 ? 'eye-o' : 'closed-eye'"
                @click-right-icon="showPwd1 = !showPwd1" />
                <van-field class="primary-input" v-model="param.pwd" :type="showPwd2 ? 'text' : 'password'"
                :label="$t('pwd.pwd2')" label-align="top" :clearable="true" clear-trigger="always"
                :rules="[{ required: true }]" :right-icon="showPwd2 ? 'eye-o' : 'closed-eye'"
                @click-right-icon="showPwd2 = !showPwd2" @input="inputPwd" />
                <div class="check-pwd-tips" :class="check1 ? 'success' : ''">{{ $t('pwd.tips1') }}</div>
                <div class="check-pwd-tips" :class="check2 ? 'success' : ''">{{ $t('pwd.tips2') }}</div>
                <div class="check-pwd-tips" :class="check3 ? 'success' : ''">{{ $t('pwd.tips3') }}</div>
                <van-field class="primary-input" v-model="param.rePwd" :type="showPwd3 ? 'text' : 'password'"
                :label="$t('pwd.pwd3')" label-align="top" :clearable="true" clear-trigger="always"
                :rules="[{ required: true }]" :right-icon="showPwd3 ? 'eye-o' : 'closed-eye'"
                @click-right-icon="showPwd3 = !showPwd3" @input="inputPwd" />
            </van-cell-group>
            <div class="ml-4 mr-4 mt-8">
                <van-button class="btn-fill-orange" :disabled="btnState" style="width: 100%;" native-type="submit">{{ $t('pwd.submit')
                }}</van-button>
            </div>
        </van-form>

    </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell__value.van-field__value) {
    flex: 1;
}

:deep(.van-cell:after) {
    right: 0 !important;
    left: 0 !important;
}

:deep(.van-cell-group.van-cell-group--inset) {
    margin: 0 !important;
}

.check-pwd-tips {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-orange);
  padding-left: 16px;
  padding-top: 8px;
}

.check-pwd-tips.success {
  color: var(--color-success);
}
</style>
