<script setup lang="ts">
definePage({
  name: "orderInfoUnPay",
  meta: {
    level: 2,
    title: "orderInfoUnPay",
    i18n: "countryList.title",
  },
});

import arrow_down_fill from "@/assets/icon/arrow_down_fill.png";
import GoodsImg from "@/pages/goods/img.vue";
const { t } = useI18n();
import type { ShopInfoRes } from "@/types/shop";
import { getShopId, getShopInfo, getShopName, setOrderInfo } from "@/storage/localStorage";
const router = useRouter();
const onBack = function () {
  router.back();
};

const shopInfo: ShopInfoRes = getShopInfo();

import {
  getCarList,
  getDefaultAddress,
  submitOrder,
  getShopPayMsg,
} from "@/api/goodsCar";
onMounted(() => {
  getAddr();
  getSendCost();
});

import { showToast } from "vant";
import { getUserInfo } from "@/storage/localStorage";
import type { LoginData } from "@/types/user";
const userinfo: LoginData = getUserInfo();
let nuserId = userinfo.id;
import type { AddrRes } from "@/types/order";
const addrObj = ref<AddrRes>({
  id: 0,
  lastName: "",
  firstName: "",
  telephone: "",
  address: "",
  sysCountryId: 0,
  isDefault: 0,
});
const getAddr = async () => {
  const { code, data } = await getDefaultAddress(nuserId);
  if (code == 200) {
    addrObj.value = data;
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

import type {
  Batchings,
  CarListRes,
  NordersDelivery,
  NordersDetailList,
  ShopSendRes,
  SubmitOrderReq,
  TaxList,
} from "@/types/goodsCar";
const shopId = getShopId();
const list = ref<CarListRes[]>([]);
let number = ref<number>();
let itemTotal = ref<number>(0);
let goodsTypeNumber = ref<number>();
const submitParams = ref<SubmitOrderReq>({
  bstatus: 0,
  code: "",
  deliveryAmount: 0,
  deliveryMemo: "",
  factAmount: 0,
  id: 0,
  isDelivery: 0,
  name: "",
  nordersDelivery: undefined,
  nordersDetailList: [],
  nordersPayList: [],
  nuserId: 0,
  orderMemo: "",
  parentId: "",
  payAmount: 0,
  payBy: 0,
  payStatus: 0,
  point: 0,
  pointsRate: 0,
  refundAmount: 0,
  setAreaId: 0,
  setAreaName: "",
  setOrderTypeId: 0,
  setOrderTypeName: "",
  shopId: 0,
  shopName: "",
  source: 0,
  status: 0,
  sysCurrencyCode: "",
  sysCurrencyId: 0,
  sysTenantId: 0,
  totalAmount: 0,
  totalCost: 0,
  totalPerFee: 0,
  totalTaxAmount: 0,
  type: 0,
  updateTime: 0,
  userName: "",
});
const getAdList = async () => {
  const { code, data } = await getCarList(shopId, nuserId);
  if (code == 200) {
    list.value = data.filter((v) => v.isSelected === 1);
    goodsTypeNumber.value = list.value.length;
    number.value = 0;
    for (let item of list.value) {
      number.value += item.num;
      item.checked = item.isSelected === 1;
    }
    const temp = list.value;
    itemTotal.value = 0;
    for (let t of temp) {
      itemTotal.value += t.num * t.showPrice;
    }
    submitParams.value = getParam();
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const checked = ref("1");
let btnState = ref<boolean>(false);
const submit = async () => {
  if (btnState.value) return;
  btnState.value = true;
  const { code, data } = await submitOrder(submitParams.value);
  if (code == 200) {
    setOrderInfo(data)
    router.push("selectPayMethod")
    return;
  }
  btnState.value = false;
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const onChange = function() {
  submitParams.value = getParam();
}

import { getGoodsCountPrice } from "../goods/price";
const time = new Date().getTime();
let senAmount = ref<number>(0);
const taxMap = ref<Map<number, TaxList>>(new Map());
const getParam = function () {
  taxMap.value = new Map<number, TaxList>()
  if (+checked.value === 1 && shopSendRes.isTax === 1) {
    for (let item of shopSendRes.deliveryCostList) {
      if (item.sOrderAmount < itemTotal.value && item.eOrderAmount >= itemTotal.value) {
        senAmount.value = item.amount;
        break;
      }
    }
  } else {
    senAmount.value = 0
  }
  const nordersDetailList: NordersDetailList[] = [];
  for (let item of list.value.filter((v) => v.checked === true)) {
    const avePrice = getGoodsCountPrice(
      {
        num: item.num,
        prices: item.prices,
        fixPrice: item.prices[0].price,
      },
      item.prices[0].type
    );
    item.avePrice = avePrice;
    item.batchAmounts = getBatchPrice(item);
    let innerTax = getInnerTaxRateTotal(item.taxList);
    let part = senAmount.value * (item.showPrice / itemTotal.value);
    item.realPrice =
      (item.avePrice + part + item.batchAmounts) * (1 / (1 + innerTax / 100));
    let realPrice = item.realPrice * item.num;

    let bs: Batchings[] = [];
    for (let b of item.detailList) {
      const bo: Batchings = {
        amount: +(b.number * b.price).toFixed(2),
        batchingDetailId: b.batchingDetailId,
        batchingDetailName: b.batchingDetailName,
        batchingId: b.batchingId,
        batchingName: b.batchingName,
        id: b.id,
        nordersDetailId: null,
        price: b.price,
        totalNum: b.number,
        updateTime: time,
      };
      bs.push(bo);
    }

    let ts: TaxList[] = [];
    for (let t of item.taxList) {
      let a = realPrice * (t.taxRate / 100);
      const to: TaxList = {
        amount: +a.toFixed(2),
        id: null,
        name: t.name,
        nordersDetailId: t.nordersDetailId,
        nordersId: t.nordersId,
        setTaxId: t.id,
        taxRate: t.taxRate,
        taxType: t.taxType,
        updateTime: time,
        taxAmount: t.taxAmount,
        taxTotalAmount: a,
      };
      ts.push(to);
    }

    let obj: NordersDetailList = {
      amount: item.avePrice * item.num,
      batchingList: bs,
      carDetailId: item.detailId,
      color: item.color,
      cost: 0,
      goodsId: item.goodsId,
      goodsName: item.name,
      goodsSkuId: item.goodsSkuId,
      goodsSkuName: item.skuName,
      goodsTypeId: item.goodsTypeId,
      goodsTypeName: item.goodsTypeName,
      id: null,
      memo: item.memo,
      ngoodsDetailId: item.ngoodsDetailId,
      ngoodsId: item.ngoodsId,
      nordersId: null,
      picUrl: item.picUrl,
      price: avePrice,
      returnNum: 0,
      shape: item.shape,
      showType: item.showType,
      sku: item.sku,
      taxList: ts,
      totalNum: item.num,
      type: item.type,
      unit: item.unit,
      updateTime: time,
      showPrice: 0
    };
    nordersDetailList.push(obj);
  }

  const param: SubmitOrderReq = {
    bstatus: null,
    code: "",
    deliveryAmount: senAmount.value,
    deliveryMemo: "",
    factAmount: 0,
    id: null,
    isDelivery: +checked.value,
    name: "",
    nordersDelivery: null,
    nordersDetailList: nordersDetailList,
    nordersPayList: [],
    nuserId: userinfo.id,
    orderMemo: "",
    parentId: null,
    payAmount: 0,
    payBy: 0,
    payStatus: null,
    point: null,
    pointsRate: null,
    refundAmount: 0,
    setAreaId: shopInfo.setAreaId,
    setAreaName: shopInfo.setAreaName,
    setOrderTypeId: null,
    setOrderTypeName: "",
    shopId: shopId,
    shopName: getShopName(),
    source: 3,
    status: 1,
    sysCurrencyCode: shopInfo.sysCurrencyCode,
    sysCurrencyId: shopInfo.sysCurrencyId,
    sysTenantId: shopInfo.sysTenantId,
    totalAmount: itemTotal.value,
    totalCost: 0,
    totalPerFee: 0,
    totalTaxAmount: 0,
    type: 1,
    updateTime: time,
    userName: userinfo.name,
  };

  for (let n of param.nordersDetailList) {
    for (let t of n.taxList) {
      param.totalTaxAmount += t.taxType == 2 ? t.amount : 0;
      if (taxMap.value.has(t.setTaxId)) {
        const tempItem: TaxList = taxMap.value.get(t.setTaxId);
        tempItem.amount += t.amount;
        taxMap.value.set(t.setTaxId, tempItem);
      } else {
        taxMap.value.set(t.setTaxId, t);
      }
    }
  }
  param.totalTaxAmount = +param.totalTaxAmount.toFixed(2);
  param.totalAmount += param.totalTaxAmount;

  if (+checked.value == 1) {
    for (let item of shopSendRes.deliveryCostList) {
      if (
        item.sOrderAmount < param.totalAmount &&
        item.eOrderAmount >= param.totalAmount
      ) {
        param.totalAmount += item.amount;
      }
    }
  }
  param.totalAmount = +param.totalAmount.toFixed(2);
  let obj: NordersDelivery = {
    address: shopSendRes.addr,
    deliveryUserName: "",
    deliveryUserTelephone: "",
    id: shopSendRes.id,
    memo: "",
    name: shopSendRes.name,
    nordersId: null,
    nuserAddressId: null,
    telephone: shopSendRes.telephone,
  };
  param.nordersDelivery = obj;
  return param;
};

const getBatchPrice = function (goods: CarListRes) {
  let batchAmounts = 0;
  for (let detail of goods.detailList) {
    batchAmounts += detail.price;
  }
  return batchAmounts;
};

const getInnerTaxRateTotal = function (taxList: TaxList[]) {
  let innerTaxRateTotal: number = 0;
  for (let tax of taxList) {
    innerTaxRateTotal += tax.taxType == 1 ? tax.taxRate : 0;
  }

  return innerTaxRateTotal;
};

let shopSendRes: ShopSendRes = null;
const getSendCost = async () => {
  const { code, data } = await getShopPayMsg(shopId);
  if (code == 200) {
    shopSendRes = data;
    getAdList();
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

const showBottom = ref<boolean>(false);
const showGoods = ref<boolean>(false);
const showOrderMemo = ref<boolean>(false);
const showSendMemo = ref<boolean>(false);
const formatter = (value: string) => value.trim()
const toAddrList = function() {
  router.push("/profile/address")
}
</script>

<template>
  <Container class="pl-0 pr-0 flex-column container-body" flex justify-between>
    <!-- 展示商品列表 -->
    <van-popup
      v-model:show="showGoods"
      position="bottom"
      :style="{ height: '50%' }"
      z-index="3"
      round
    >
      <div flex class="flex-column" style="height: 100%;">
        <van-row boder-bottom-line class="goods-title">
          <van-col span="24">
            <div
              text-16
              fw-400
              py-16
              justify-between
              class="d-flex align-items boder-bottom-line color-black"
              px-24
            >
              <div></div>
              <div>{{ $t("order.goodsInfo") }}</div>
              <div flex class="align-center" @click="showGoods = false">
                <van-icon name="cross" size="20" />
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row class="goods-body" flex-1 px-16 mx-8 boder-bottom-line>
          <van-col
            text-12
            fw-400
            color-black
            pt-16
            pb-16
            span="24"
            justify-between
            class="d-flex align-items boder-bottom-line"
            v-for="(item, index) of list"
            :key="index"
            flex
            style="min-height: 93px; min-width: 0"
          >
            <div flex class="align-center" style="min-width: 0;width: 100%">
              <div class="img-60" mr-12>
                <GoodsImg
                  :showType="item.showType"
                  :color="item.color"
                  :picUrl="item.picUrl"
                />
              </div>
              <div
                class="flex-column content-item"
                flex-1
                flex
                justify-between
                style="min-width: 0"
              >
                <div flex justify-between class="align-center">
                  <div class="ellipsis-space" style="width: 70%">
                    {{ item.name }}{{ item.type === 2 ? `(${item.skuName})` : "" }}
                  </div>
                  <div fw-500 text-end style="width: 30%">{{ item.showPrice }}</div>
                </div>
                <div class="color-light-black ellipsis-space">
                  <span mr-4 :style="`opacity: ${item.memo ? 1 : 0}`">+</span>
                  <span>{{ item.memo }}</span>
                </div>
                <div>{{ $t("order.number") }}:{{ item.num }}</div>
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="btn-next" px-24>
            <van-button class="btn-fill-orange" @click="showGoods = false">{{
              $t("order.next")
            }}</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
    <!-- 展示税费详情 -->
    <van-popup
      v-model:show="showBottom"
      position="bottom"
      :style="{ height: '42%' }"
      z-index="1"
      round
    >
      <van-row mb-70 px-16 mx-8 class="cost-row">
        <van-col
          text-16
          fw-400
          color-black
          pt-16
          pb-16
          span="24"
          justify-between
          class="d-flex align-items boder-bottom-line"
        >
          <div>{{ $t("order.money") }}:</div>
          <div>{{ itemTotal }}</div>
        </van-col>
        <van-col
          text-16
          fw-400
          color-black
          pt-16
          pb-16
          span="24"
          justify-between
          class="d-flex align-items boder-bottom-line"
          v-for="map in taxMap.values()"
        >
          <div>{{ map.name }},{{ map.taxRate }}%({{$t(`order.tax${map.taxType}`)}}):</div>
          <div>{{ +checked === 1 ? +map.taxTotalAmount.toFixed(2) : +map.amount.toFixed(2) }}</div>
        </van-col>
        <van-col
          text-16
          fw-400
          color-black
          pt-16
          pb-16
          span="24"
          justify-between
          class="d-flex align-items boder-bottom-line"
          v-if="+checked === 1"
        >
          <div>{{ $t("order.sendMoney") }}:</div>
          <div>{{ senAmount }}</div>
        </van-col>
        <van-col
          text-16
          fw-400
          color-black
          pt-16
          pb-16
          span="24"
          justify-between
          class="d-flex align-items"
        >
          <div>{{ $t("order.totalMoney") }}:</div>
          <div>{{ submitParams.totalAmount }}</div>
        </van-col>
      </van-row>
    </van-popup>
    <!-- 展示订单备注 -->
    <van-popup
      v-model:show="showOrderMemo"
      position="bottom"
      :style="{ height: '50%' }"
      z-index="3"
      round
    >
      <div flex class="flex-column" style="height: 100%;">
        <van-row boder-bottom-line class="goods-title">
          <van-col span="24">
            <div
              text-16
              fw-400
              py-16
              justify-between
              class="d-flex align-items boder-bottom-line color-black"
              px-24
            >
              <div></div>
              <div>{{ $t("order.orderMemo") }}</div>
              <div flex class="align-center" @click="showOrderMemo = false">
                <van-icon name="cross" size="20" />
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row class="goods-body" flex-1 px-16 mx-8 boder-bottom-line>
          <van-col
            text-12
            fw-400
            color-black
            pt-16
            pb-16
            span="24"
            justify-between
            class="d-flex align-items"
            flex
            style="min-height: 93px; min-width: 0"
          >
            <van-field
              v-model="submitParams.orderMemo"
              rows="1"
              autosize
              type="textarea"
              :placeholder='$t("order.fillMemo")'
              maxlength="200"
              show-word-limit
              :formatter="formatter"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="btn-next" px-24>
            <van-button class="btn-fill-orange" @click="showOrderMemo = false">{{
              $t("order.confirm")
            }}</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
    <!-- 展示配送备注 -->
    <van-popup
      v-model:show="showSendMemo"
      position="bottom"
      :style="{ height: '50%' }"
      z-index="3"
      round
    >
      <div flex class="flex-column" style="height: 100%;">
        <van-row boder-bottom-line class="goods-title">
          <van-col span="24">
            <div
              text-16
              fw-400
              py-16
              justify-between
              class="d-flex align-items boder-bottom-line color-black"
              px-24
            >
              <div></div>
              <div>{{ $t("order.sendMemo") }}</div>
              <div flex class="align-center" @click="showSendMemo = false">
                <van-icon name="cross" size="20" />
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row class="goods-body" flex-1 px-16 mx-8 boder-bottom-line>
          <van-col
            text-12
            fw-400
            color-black
            pt-16
            pb-16
            span="24"
            justify-between
            class="d-flex align-items"
            flex
            style="min-height: 93px; min-width: 0"
          >
            <van-field
              v-model="submitParams.deliveryMemo"
              rows="1"
              autosize
              type="textarea"
              :placeholder='$t("order.fillMemo")'
              maxlength="200"
              show-word-limit
              :formatter="formatter"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="btn-next" px-24>
            <van-button class="btn-fill-orange" @click="showSendMemo = false">{{
              $t("order.confirm")
            }}</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
    <VanNavBar
      :title="$t('order.title')"
      :fixed="true"
      clickable
      left-arrow
      @click-left="onBack"
    >
      <template #left>
        <van-icon name="cross" size="22" />
      </template>
    </VanNavBar>
    <div style="flex: 1" overflow-auto>
      <van-row
        ml-8
        mr-8
        mt-16
        mb-16
        pt-12
        pb-12
        pl-16
        pr-16
        style="background-color: #fff"
      >
        <van-col span="24" text-16 fw-500 class="color-black">{{
          shopInfo.nshopName
        }}</van-col>
        <van-col span="24" text-12 fw-400 class="color-light-black"
          ><span mr-8>{{ $t("order.hotPhone") }}</span
          ><span>{{ shopInfo.telephone }}</span></van-col
        >
        <van-col span="24" text-12 fw-400 class="color-light-black"
          ><span mr-8>{{ $t("order.addr") }}</span
          ><span>{{ shopInfo.addr }}</span></van-col
        >
      </van-row>
      <van-row
        ml-8
        mr-8
        mt-16
        mb-16
        pt-12
        pb-12
        pl-16
        pr-16
        style="background-color: #fff"
      >
        <van-col span="24">
          <van-radio-group v-model="checked" @change="onChange" direction="horizontal">
            <van-radio name="1">{{ $t("order.get1") }}</van-radio>
            <van-radio name="2">{{ $t("order.get2") }}</van-radio>
          </van-radio-group>
        </van-col>
        <van-col span="24" text-12 fw-400 class="d-flex" justify-between align-center>
          <div mr-8 class="color-light-black">{{ $t("order.receiveUser") }}</div>
          <van-icon name="manager-o" class="color-orange" size="20" @click="toAddrList" />
        </van-col>
        <van-col span="24" text-12 fw-400
          ><span mr-8 class="color-light-black">{{ $t("order.name") }}</span
          ><span class="color-black">{{ addrObj.firstName }}{{ addrObj.lastName }}</span></van-col
        >
        <van-col span="24" text-12 fw-400
          ><span mr-8 class="color-light-black">{{ $t("order.phone") }}</span
          ><span class="color-black">{{ addrObj.telephone }}</span></van-col
        >
        <van-col span="24" text-12 fw-400
          ><span mr-8 class="color-light-black">{{ $t("order.addr") }}</span
          ><span class="color-black">{{ addrObj.address }}</span></van-col
        >
      </van-row>
      <van-row
        ml-8
        mr-8
        mt-16
        mb-16
        pt-12
        pb-12
        pl-16
        pr-16
        style="background-color: #fff"
      >
        <van-col span="24" text-12 fw-500 class="color-black">{{
          $t("order.goods")
        }}</van-col>
        <van-col span="24" class="d-flex" pt-12 justify-between align-center>
          <div style="width: 80%" flex overflow-x-hidden>
            <div class="img-60" mr-12 v-for="item of list" :key="item.goodsSkuId">
              <GoodsImg
                :showType="item.showType"
                :color="item.color"
                :picUrl="item.picUrl"
              />
            </div>
          </div>
          <div
            text-12
            fw-400
            flex
            justify-end
            class="color-light-black align-center"
            @click="showGoods = true"
          >
            <div>
              <span>{{ $t("order.total") }}</span
              ><span>{{ goodsTypeNumber }}</span
              ><span>{{ $t("order.unit") }}</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </van-col>
        <van-col
          span="24"
          text-12
          fw-400
          mt-24
          class="color-black d-flex align-center"
          justify-between
        >
          <div>{{ $t("order.orderMemo") }}</div>
          <van-icon name="arrow" @click="showOrderMemo = true"/>
        </van-col>
        <van-col
          span="24"
          text-12
          fw-400
          mt-24
          class="color-black d-flex align-center"
          justify-between
          v-if="+checked === 1"
        >
          <div>{{ $t("order.sendMemo") }}</div>
          <van-icon name="arrow" @click="showSendMemo=true"/>
        </van-col>
        <van-col
          span="24"
          text-12
          fw-400
          mt-24
          class="color-light-black d-flex align-center"
          justify-end
        >
          <span>{{ $t("order.total") }}</span>
          <span mr-4 ml-4>{{ goodsTypeNumber }}</span>
          <span mr-8>{{ $t("order.unit") }}</span>
          <span mr-8>{{ $t("order.money") }}</span>
          <span class="color-orange">{{ submitParams.totalAmount }}</span>
        </van-col>
      </van-row>
    </div>
    <div
      class="bottom align-center"
      flex
      justify-end
      pt-12
      pb-12
      pl-16
      pr-16
      style="background-color: #fff"
    >
      <img
        class="img-24"
        mr-4
        :src="arrow_down_fill"
        alt=""
        :style="showBottom ? 'transform: rotate(180deg);' : ''"
        @click="showBottom = !showBottom"
      />
      <div text-24 fw-500 style="color: rgb(235, 57, 18)">
        {{ submitParams.totalAmount }}
      </div>
      <div class="btn-outer" ml-8>
        <van-button class="btn-fill-orange" :disabled="btnState" @click="submit">{{
          $t("order.submit")
        }}</van-button>
      </div>
    </div>
  </Container>
</template>

<style lang="less" scoped>
.container-body {
  height: 100vh;
}

.img-60 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow-x: hidden;
}

.bottom {
  height: 70px;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.07);
  z-index: 2;
}

.btn-outer {
  min-width: 100px;

  .btn-fill-orange {
    width: 100% !important;
  }
}

.cost-row .van-col {
  height: 55px;
}

.content-item {
  height: 60px;
}

.goods-body {
  height: calc(100% - 56px);
  overflow: auto;
}

.goods-title {
  height: 56px;
}

.btn-next {
  .btn-fill-orange {
    width: 100%;
  }
}
</style>
