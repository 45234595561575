<script setup lang="ts">
definePage({
    name: 'delAccount',
    meta: {
        level: 2,
        title: 'delAccount',
        i18n: 'checkEmail.title2',
    },
})

import check1 from '@/assets/icon/check1.png';
import check2 from '@/assets/icon/check2.png';
import check3 from '@/assets/icon/check3.png';
const { t } = useI18n()
let settingDatas = ref<Array<any>>([])
onMounted(() => {
    settingDatas.value = [
        { id: 1, title: t("delAccount.title1"), content: t("delAccount.info1"), img: check3 },
        { id: 2, title: t("delAccount.title2"), content: t("delAccount.info2"), img: check1 },
        { id: 3, title: t("delAccount.title3"), content: t("delAccount.info3"), img: check2 },
    ]
})
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const user: LoginData = getUserInfo()
import { showToast } from 'vant';
import { sendVerify } from '@/api/settings';
import type { SendEmailReq } from '@/types/settings';
let btnState = ref<boolean>(false)
const onSubmit = async () => {
    if (btnState.value) return
    btnState.value = true
    let param: SendEmailReq = {
        email: user.email,
        id: user.id,
        languageCode: locale.value,
        operate: 1
    }
    const { code } = await sendVerify(param)
    if (code == 200) {
        btnState.value = false
        showToast({
            message: t('delAccount.emailIsSend'),
            position: 'bottom',
        });
        showTips.value = false
        return
    }
    btnState.value = false
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
};

let showTips = ref<boolean>(false)
</script>

<template>
    <Container class="d-flex flex-column align-center del-container">
        <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
            <div class="pt-5 pb-5 pl-4 pr-4 fz-3 fw-400" style="background-color: #fff;color: rgb(110, 110, 110);">
                <div class="mb-4">
                    <span>{{ $t('delAccount.tips1') }}</span>
                </div>
                <div class="mb-6">
                    <span>{{ $t('delAccount.tips2') }}</span>
                    <span>({{ user.email }}),</span>
                    <span>{{ $t('delAccount.tips3') }}</span>
                </div>
                <van-button class="btn-fill-orange" @click="onSubmit" style="width: 100%;">{{ $t('delAccount.confirm') }}</van-button>
            </div>
        </van-dialog>
        <div class="d-flex flex-column align-center justify-between" style="height: 100%;">
            <div class="d-flex flex-column align-center text-center">
                <div class="mt-8 mb-4 fz-4 fw-400 color-black">{{ $t('delAccount.subTitle') }}</div>
                <div class="mb-4 fontSize-14 fw-400 color-light-black">{{ $t('delAccount.content') }}</div>
                <div class="mb-6 fontSize-14 fw-400 color-orange">{{ $t('delAccount.warn') }}</div>
                <div v-for="item of settingDatas" class="d-flex justify-between align-50% mb-4 card-item">
                    <div class="text-start">
                        <div>
                            <div class="color-black fontSize-14 fw-400">{{ item.title }}</div>
                            <div class="color-light-black fz-3 fw-400">{{ item.content }}</div>
                        </div>
                    </div>
                    <img :src="item.img" class="img-32" />
                </div>
            </div>
            <van-button class="btn-fill-orange mb-8" style="width: 100%;" :disabled="btnState" @click="showTips = true">{{
                $t('delAccount.submit') }}</van-button>
        </div>
    </Container>
</template>

<style lang="less" scoped>
.del-container div {
    width: 100%;
}

.card-item {
    background-color: rgb(242, 242, 242);
    border-radius: 10px;
    padding: 10px 16px;
}
</style>
