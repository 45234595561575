<script setup lang="ts">
definePage({
  name: 'userInfo',
  meta: {
    level: 2,
    title: 'userInfo',
    i18n: 'userInfo.title'
  },
})
import { getUserInfo } from '@/storage/localStorage';
const { t } = useI18n()
let settingDatas = ref<Array<any>>([])
import type { LoginData } from '@/types/user';
onMounted(() => {
  const user: LoginData = getUserInfo()
  settingDatas.value = [
    { id: 1, className: '', title: t("userInfo.name"), value: user.name || user.email, url: 'userInfo/edit' },
    { id: 0, className: 'mb-3', title: t("userInfo.qrcode"), value: '', icon: 'qr', url: 'userInfo/qrcode' },
  ]
})

const router = useRouter()
const toNextPage = function (url) {
  router.push(url)
}
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-list>
      <van-cell class="list-cell pl-4 pr-4" :class="item.className" v-for="item in settingDatas" :key="item.id"
        :title="item.title" :value="item.value" @click="toNextPage(item.url)" is-link>
        <template #value v-if="item.icon">
          <van-icon :name="item.icon" />
        </template>
      </van-cell>
    </van-list>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}
</style>
