<script setup lang="ts">
definePage({
    name: 'rules1',
    meta: {
        level: 3,
        title: 'rules1',
        i18n: 'settings.name8',
    },
})

const title = 'Effective Date: December 16,2021'
const sub = 'CONTENTS'
const content = `1.1 Scope.Welcome to SHElN.com. These Terms
                & Conditions("Agreement" or "Terms" )are a contract between you and RoadgetBusiness Pte. Ltd., as operator
                of the Site and App(as definedbelow),and Fashion Choice Pte. Ltd.(the "Company" ), as thecompany that uses
                the Site and App to sell products to you,collect payment and process your returns (where applicable"SHEIN" ,
                "we" , "us" and "our" shall refer to Roadget Business Pte.Ltd.and/or`
</script>

<template>
    <Container>
        <div class="d-flex flex-column" style="height: 100%;">
            <div class="mt-8 mb-4 fz-4 fw-500 color-black">{{ title }}</div>
            <div class="mb-4 fz-4 fw-500 color-black">{{ sub }}</div>
            <div class="mb-4 fontSize-14 fw-400 color-black">1.GENERAL ><br>
                2. USE OF OUR SERVICES ><br>
                3. PRIVACY & COOKIE POLICY ><br>
                4. ERRORS ><br>
                5.TRADE RULES ><br>
                6.INTELLECTUAL PROPERTY AND OWNERSHIP ><br>
                7.THIRD PARTY LINKS AND RESOURCES ><br>
                8.TEXT MESSAGING PROGRAM ><br>
                9.EVENTS BEYOND OUR CONTROL><br>
                10.LIABILITY FOR PURCHASED PRODUCTS, WAIVER, ANDSTATUTORY CONSUMER RIGHTS ><br>
                11. LIMITATION OF LIABILITY ><br>
                12.LEGAL DISPUTES AND ARBITRATION ><br>
                13.LEGAL TERMS ><br>
                14.COPYRIGHTINFRINGEMENT><br>
                15.BUSINESS TRANSFERS ><br>
                16.CONTACT US ></div>
            <div class="mb-4 fz-4 fw-400 color-black">1.GENERAL</div>
            <div class="mb-6 fontSize-14 fw-400" style="color: rgb(110, 110, 110)">
                {{ content }}
            </div>
        </div>
    </Container>
</template>

<style lang="less" scoped></style>
