<script setup lang="ts">
definePage({
  name: 'accountManage',
  meta: {
    level: 2,
    title: 'accountManage',
    i18n: 'accounts.title',
  },
})
import {getUserInfo} from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const { t } = useI18n()
let settingDatas = ref<Array<any>>([])
onMounted(()=>{
  const user: LoginData = getUserInfo()
  settingDatas.value = [
    { id: 1, title: t("accounts.email"), value: user.email, url: 'accountManage/checkEmail' },
    { id: 2, title: t("accounts.phone"), value: '', url: user.telephone ? 'accountManage/setNewPhone' : 'accountManage/setFirstPhone', hasBtn: true },
    { id: 3, title: t("accounts.pwd"), value: '', url: 'accountManage/updatePwd' },
    { id: 4, title: t("accounts.delAccount"), value: '', url: 'accountManage/delAccount' },
  ]
})
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-list>
      <van-cell class="list-cell pl-4 pr-4" :class="item.className" v-for="item in settingDatas" :key="item.id" :title="item.title"
        :value="item.value" is-link :to="item.url">
        <template #value v-if="item.hasBtn">
          <div class="btn-add">{{ $t("accounts.add") }}</div>
        </template>
      </van-cell>
    </van-list>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell .van-cell__title) {
  flex: unset;
  width: 40%;
}

:deep(.van-cell .van-cell__value) {
  flex: unset;
  width: 60%;
}

:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}

.btn-add {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 74, 33, 1);
}
</style>
