<script setup lang="ts">
definePage({
  name: 'rules',
  meta: {
    level: 3,
    title: 'rules',
    i18n: 'settings.name7',
  },
})

const title = 'Effective Date: December 16,2021'
const sub = 'Introduction and overview'
const content = `This Privacy Policy ("Policy" )explains how your personalinformation is collected, used, shared and processed whenaccessing this website or using other services
          provided by itsoperator, including any written or electronic communications andpurchases performed(collectively,
          the "Services" ) as well as therights and choices you have associated with that information.The Services include
          any services provided via this website.ocated at SHElN.com (the "Site" ),our mobile applications (theApps"),our
          accounts and fan pages on social media platforms(the "Social Media Accounts" ),and any other websites,
          pages,features,or content owned and operated by the Company thathyperlink to this Policy.The company responsible
          for selling the products via the Site andApps and providing the Services (and therefore, the paymentcollection
          entity)is Fashion Choice Pte. Ltd, 22 Sin Ming Lane,#06-76,Midview City, Singapore 573969, acting as a licensee
        ofRoadget Business Pte.Ltd., 12 Marina Boulevard, #15-01,MarinaBay Financial Centre,Singapore 018982.Therefore,
        the termsCompany" "we" "us," and "our" refer to different entities.Please read our Terms and Conditions and this
        Policy beforeaccessing or using our Services. lf you cannot agree with thisPolicy or the Terms and Conditions,
        please do not access or useour Services.By using our Services, you accept the Terms andConditions and Privacy
        Policy on the site you are using.`
</script>

<template>
  <Container>
    <div class="d-flex flex-column" style="height: 100%;">
      <div class="mt-8 mb-4 fz-4 fw-500 color-black">{{ title }}</div>
        <div class="mb-4 fz-4 fw-500 color-black">{{ sub }}</div>
        <div class="mb-6 fontSize-14 fw-400" style="color: rgb(110, 110, 110)">{{ content }}</div>
    </div>
  </Container>
</template>

<style lang="less" scoped></style>
