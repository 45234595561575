<script setup lang="ts">
definePage({
  name: 'home',
  meta: {
    level: 1,
  },
})
import arrowDown from '@/assets/icon/arrow-down-white.png';
import point from '@/assets/icon/point.png';
import scan_white from '@/assets/icon/scan_white.png';
import CarIcon from '@/pages/goods/car.vue';
const router = useRouter()
const toShop = function () {
  router.push(`home/shop/list`)
}

const searchCounryList = function () {

}
import { getShopId, getShopName, hasToken } from '@/storage/localStorage';
import { adList, typeList, subjectList, homeGoodstList } from '@/api/home';

onMounted(() => {
  if (hasToken()) {
    getAds()
    getTyps()
    getSubjects()
    getGoods()
  }
})

const { t } = useI18n()
import { showToast } from 'vant';
import type { adListRes, HomeGoodsInfo, PageObj, typeListRes } from '@/types/home';
let images = ref<adListRes[]>([])
const shopId = getShopId()
const shopName = getShopName()
const getAds = async function () {
  const { code, data } = await adList(shopId)

  if (code == 200) {
    images.value = data
    return
  }
  images.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

let types = ref<typeListRes[]>([])
const getTyps = async function () {
  const { code, data } = await typeList(shopId)

  if (code == 200) {
    types.value = data
    return
  }
  types.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

let subjects = ref<adListRes[]>([])
const getSubjects = async function () {
  const { code, data } = await subjectList(shopId)

  if (code == 200) {
    subjects.value = data
    return
  }
  subjects.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const page: PageObj = {
  pageNum: 1,
  pageSize: 999
}
let goods = ref<HomeGoodsInfo[]>([])
const getGoods = async function () {
  const { code, data } = await homeGoodstList(shopId, page)

  if (code == 200) {
    goods.value = data.list
    return
  }
  goods.value = []
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

import { getColor } from "@/utils/colorUtil";
const getBgColor = function (color) {
  const _color = getColor(color)
  return _color.val
}

const toAdGoodsList = function(image: adListRes) {
  router.push(`home/goods/list?id=${image.id}&shopId=${shopId}&name=${image.name}&type=1`)
}

const toSbGoodsList = function(image: adListRes) {
  router.push(`home/goods/list?id=${image.id}&shopId=${shopId}&name=${image.name}&type=2`)
}

const toTypeGoods = function(type: typeListRes) {
  router.push(`/type?id=${type.id}`)
}

const imagesblank = [
  t("home.blank"),
  t("home.blank"),
  t("home.blank"),
  t("home.blank")
]

import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const userinfo: LoginData = getUserInfo()
let nuserId = null
if (userinfo) {
  nuserId = userinfo.id
}

const toGoodsInfo = function(g: HomeGoodsInfo) {
  router.push(`/home/goods/info?goodsId=${g.goodsId}&nGoodsId=${g.ngoodsId}&type=${g.type}`)
}
</script>

<template>
  <Container :padding-x="0" :padding-t="0">
    <div class="pl-4 pr-4 pt-2 pb-2"
      style="background-image: linear-gradient(to bottom, var(--color-orange),  #f7f8fa 85%, #f7f8fa);">
      <div class="flex justify-between" @click="toShop">
        <div class="flex align-center">
          <img :src="point" class="img-24 mr-2" />
          <span class="color-white ellipsis-space" max-w-4xl>{{ shopName }}</span>
          <img :src="arrowDown" class="img-12 ml-2" />
        </div>
        <img :src="scan_white" class="img-24" />
      </div>
      <van-search @input="searchCounryList" background="unset" :placeholder="$t('home.search')" shape="round"
        :clearable="false" />
      <van-swipe :autoplay="3000" lazy-render v-if="images && images.length > 0">
        <van-swipe-item v-for="image in images" :key="image.id" @click="toAdGoodsList(image)">
          <img :src="image.picUrl" style="width: 100%;height: 141px;border-radius: 8px;" />
        </van-swipe-item>
      </van-swipe>
      <van-swipe :autoplay="3000" lazy-render v-else>
        <van-swipe-item v-for="(image, index) in imagesblank" :key="index">
          <div class="flex align-center justify-center" style="width: 100%;height: 141px;border-radius: 8px;">{{ image }}</div>
        </van-swipe-item>
      </van-swipe>
      <div class="flex flex-wrap mt-2 pa-3" v-if="types && types.length > 0" style="background-color: #fff;border-radius: 8px;">
        <div class="flex flex-column justify-center align-center" style="width: 20%;" v-for="_type of types"
          :key="_type.id" @click="toTypeGoods(_type)">
          <div>
            <div class="img-48 type-item" v-if="_type.showType == 1"
              :style="`background-color: ${getBgColor(_type.color)};`">
            </div>
            <img v-else :src="_type.picUrl" class="img-48 type-item" />
          </div>
          <div class="mt-2 ellipsis-space text-center" style="width: 100%;">{{ _type.name }}</div>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 pa-3" v-else style="background-color: #fff;border-radius: 8px;">
        <div class="flex flex-column justify-center align-center" style="width: 20%;" v-for="(_type, index) of 5"
          :key="index">
          <div>
            <div class="img-48 type-item"
              :style="`background-color: ${getBgColor(+index + 1)};`">
            </div>
          </div>
          <div class="mt-2"></div>
        </div>
      </div>
    </div>
    <van-row gutter="20" class="pl-4 pr-4 pt-2 pb-2" v-if="subjects && subjects.length > 0">
      <van-col span="12" class="mb-2" v-for="_subjuect of subjects" :key="_subjuect.id" @click="toSbGoodsList(_subjuect)">
        <div class="subjuect-card">{{ _subjuect.name }}</div>
        <div class="pl-4 pr-4">
          <img :src="_subjuect.picUrl" style="width: 100%;" />
        </div>
      </van-col>
    </van-row>
    <van-row gutter="20" class="pl-4 pr-4 pt-2 pb-2" v-else>
      <van-col span="12" class="mb-2" v-for="(_subjuect, i) of 2" :key="i">
        <div class="subjuect-card"></div>
        <div class="pl-4 pr-4">
          <div style="height: 100px; width: 100%;">
          </div>
        </div>
      </van-col>
    </van-row>
    <van-list class="mb-13">
      <van-row gutter="20" class="pl-4 pr-4 pt-2 pb-2">
        <van-col span="12" class="mb-2" v-for="g of goods" :key="g.goodsSkuId">
          <div class="pa-4" style="background-color: #FFF;">
            <div @click="toGoodsInfo(g)">
              <div class="mb-4">
                <div style="height: 130px;">
                  <div v-if="g.showType == 1" :style="`background-color: ${getBgColor(g.color)};width: 100%;height: 100%`">

                  </div>
                  <img v-else :src="g.picUrl" style="width: 100%;height: 100%;" />
                </div>
              </div>
              <div class="ellipsis-space fontSize-14 fw-500">{{ g.goodsName }}</div>
              <div class="ellipsis-space fz-3 fw-400">{{ g.memo }}</div>
            </div>
            <div class="flex justify-between align-center mt-1">
              <div class="fontSize-14 fw-500 color-orange">{{ g.price }}</div>
              <CarIcon :type="g.type" :goodsId="g.goodsId" :nGoodsId="g.ngoodsId" :nUserId="nuserId"
                            :shopId="+shopId" />
            </div>
          </div>
        </van-col>
      </van-row>
    </van-list>
  </Container>
</template>
<style lang="less" scoped>
:deep(.van-search) {
  padding: 8px 0
}

.type-item {
  border-radius: 50%;
}

.subjuect-card {
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 33px;
  background: linear-gradient(180deg, rgba(227, 250, 224, 1) 0%, rgba(227, 250, 224, 0) 100%);
}
</style>