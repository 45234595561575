<script setup lang="ts">
definePage({
  name: 'checkEmail',
  meta: {
    level: 2,
    title: 'checkEmail',
    i18n: 'email.title',
  },
})
const { t } = useI18n()
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const user: LoginData = getUserInfo()
let isError = ref<boolean>(false)
let msg = ref<string>('')
let count = ref<number>(60);
const updateCountdown = function () {
  if (count.value > 0) {
    count.value--;
  }
  if (count.value === 0) {
    clearInterval(intervalId);
    count.value = 60
  }
}
let intervalId = null

import { locale } from '@/utils/i18n'
import { sendVerify, sendCode } from '@/api/settings';
import type { SendEmailCodeReq, SendEmailReq } from '@/types/settings';
const checkEmailEvent = async function () {
  intervalId = setInterval(updateCountdown, 1000);
  let param: SendEmailReq = {
    email: user.email,
    id: user.id,
    languageCode: locale.value,
    operate: 1
  }
  const { code } = await sendVerify(param)

  if (code == 200) {
    isError.value = false
    msg.value = `A confirmation email
      has been sent to you. Please click the link in the email to complete verification.`
    return
  }
  isError.value = true
  msg.value = `[${code}]:${t('code.' + code + '')}`
}

import { showToast } from 'vant';
const router = useRouter()
const updateEmail = async function() {
  if (user.isEmailChecked != 1) {
    showToast({
      message: t('checkEmail.info'),
      position: 'bottom',
    });
    return
  }

  let param: SendEmailCodeReq = {
    areaCode: '',
    batch: 1,
    code: '',
    codeId: null,
    fromAddress: user.email,
    id: user.id,
    languageCode: locale.value,
    sysCountryId: user.sysCountryId,
    type: 1
  }
  const { code, data } = await sendCode(param)
  if (code == 200) {
    router.push(`fillCode?codeId=${data.emailCodeId}`)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
}
</script>

<template>
  <Container class="d-flex align-center flex-column" style="background-color: #fff;">
    <div class="mt-10">{{ $t('checkEmail.subTitle') }}</div>
    <div class="mt-4">{{ user.email }}</div>
    <div class="mt-4 fontsize-14 fw-400 pl-4 pr-4 tips" v-if="Boolean(msg)" :class="isError ? 'error' : 'msg'">{{ msg }}
    </div>
    <div class="mt-10" style="width: 100%;">
      <van-button  class="btn-fill-orange" v-if="user.isEmailChecked != 1" :disabled="count !== 60" @click="checkEmailEvent">{{ count === 60 ?
        $t('checkEmail.check') : count }}</van-button>
      <van-button class="btn-outline-orange mt-4" @click="updateEmail">{{ $t('checkEmail.update') }}</van-button>
    </div>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell .van-cell__title) {
  flex: unset;
  width: 40%;
}

:deep(.van-cell .van-cell__value) {
  flex: unset;
  width: 60%;
}

:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}

.btn-add {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 74, 33, 1);
}

button {
  width: 100% !important;
}

.tips.msg {
  color: rgb(37, 157, 95);
}

.tips.error {
  color: red;
}
</style>
