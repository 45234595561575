<script lang="ts">
	import { defineComponent } from 'vue';
	export default defineComponent({
		beforeRouteEnter(_to, from, next) {
      if (from.path !== '/car/selectPayMethod' && from.path !== '/') {
        next(false)
        return
      }
			next();
		},
	});
</script>
<script setup lang="ts">
import { getOrderInfo, getUserInfo } from '@/storage/localStorage';
import type { SubmitOrderReq, TaxList } from '@/types/goodsCar';
import GoodsImg from "@/pages/goods/img.vue";
import { showToast } from "vant";
import { updateOrder } from '@/api/settings';
import type { CancelOrderReq } from '@/types/settings';

definePage({
  name: "carOrderInfoDisplay",
  meta: {
    level: 2,
    title: "orderInfoDisplay",
    i18n: "countryList.title",
  },
});

const router = useRouter();
const onBack = function () {
  router.replace("/car")
};

let info = ref<SubmitOrderReq>(getOrderInfo());
const taxMap = ref<Map<number, TaxList>>(new Map());
onMounted(()=>{
  for (let item of info.value.nordersDetailList) {
    let amount = item.amount
    for (let batch of item.batchingList) {
      amount += batch.amount
    }
    item.showPrice = amount

    for (let t of item.taxList) {
      if (taxMap.value.has(t.setTaxId)) {
        const tempItem: TaxList = taxMap.value.get(t.setTaxId);
        tempItem.amount += t.amount;
        taxMap.value.set(t.setTaxId, tempItem);
      } else {
        taxMap.value.set(t.setTaxId, t);
      }
    }
  }
})

const show = ref<boolean>(false)
const showCancelDialog = function() {
  show.value = true
}

const { t } = useI18n();
let btnState = ref<boolean>(false);
const submit = async () => {
  if (btnState.value) return;
  btnState.value = true;
  const param: CancelOrderReq = {
    nordersId: info.value.id,
    nuserId: getUserInfo().id,
    operation: 2
  }
  const { code } = await updateOrder(param);
  if (code == 200) {
    router.replace("/car")
    return;
  }
  btnState.value = false;
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};
</script>

<template>
  <Container class="pl-0 pr-0 flex-column container-body" flex justify-between>
    <van-dialog width="327px" v-model:show="show" :showConfirmButton="false">
      <div ma-20 style="background-color: #fff;">
        <div text-20 fw-500 mb-24 text-center>{{ $t('pay.cancelOrder') }}</div>
        <div mb-24 text-center>
          {{ $t('pay.cancelInfo') }}
        </div>
        <div flex justify-between>
          <van-button class="btn-outline-orange" @click="show = false">{{ $t('pay.cancelBtn') }}</van-button>
          <van-button class="btn-fill-orange" @click="submit">{{ $t('pay.confirmBtn') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <VanNavBar :title="info.code" :fixed="true" clickable left-arrow @click-left="onBack">
      <template #left>
        <van-icon name="cross" size="22" />
      </template>
    </VanNavBar>
    <div style="flex: 1" overflow-auto>
      <van-row style="background-color: #fff" py-20>
        <van-col span="24" text-center text-16 fw-500 class="color-orange">
          <span>{{ $t("pay.success") }}:</span>
          <span>{{ info.totalAmount }}</span>
        </van-col>
      </van-row>
      <van-row
        mx-8
        my-16
        py-12
        px-16
        style="background-color: #fff"
        flex
        class="align-center"
      >
        <van-col span="12" text-16 fw-500 class="color-orange">{{
          $t("pay.readyGoods")
        }}</van-col>
        <van-col span="12" class="d-flex" justify-end>
          <van-button class="btn-fill-orange">{{ $t("pay.addGoods") }}</van-button>
        </van-col>
      </van-row>
      <van-row
        mx-8
        mt-16
        mb-4
        py-12
        px-16
        style="background-color: #fff"
        flex
        class="align-center"
      >
        <van-col span="24" text-16 fw-500 class="color-black">{{
          $t("pay.goodsInfo")
        }}</van-col>
      </van-row>
      <van-row class="goods-body" flex-1 mx-8 boder-bottom-line>
        <van-col
          text-12
          fw-400
          color-black
          pa-16
          mt-12
          span="24"
          justify-between
          class="d-flex align-center boder-bottom-line"
          v-for="(item, index) of info.nordersDetailList"
          :key="index"
          flex
          style="min-height: 93px; min-width: 0;background-color: #fff"
        >
          <div flex class="align-center" style="min-width: 0; width: 100%">
            <div class="img-60" mr-12>
              <GoodsImg
                :showType="item.showType"
                :color="item.color"
                :picUrl="item.picUrl"
              />
            </div>
            <div
              class="flex-column content-item"
              flex-1
              flex
              justify-between
              style="min-width: 0"
            >
              <div flex justify-between class="align-center">
                <div class="ellipsis-space" style="width: 70%">
                  {{ item.goodsName
                  }}{{ item.type === 2 ? `(${item.goodsSkuName})` : "" }}
                </div>
                <div fw-500 text-end style="width: 30%">{{ item.showPrice }}</div>
              </div>
              <div class="color-light-black ellipsis-space">
                <span mr-4 :style="`opacity: ${item.memo ? 1 : 0}`">+</span>
                <span>{{ item.memo }}</span>
              </div>
              <div>
                <span>{{ item.totalNum }}</span>
                <span> x </span>
                <span v-if="item.batchingList.length > 0">
                  ( {{ item.price }} + {{item.batchingList.map(v=>v.amount).join(' + ')}} )
                </span>
                <span v-else>{{ item.price }}</span>
              </div>
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row px-16 mx-8 class="cost-row"
        style="background-color: #fff">
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
          v-for="map in taxMap.values()"
        >
          <div fw-400 class="color-light-black">{{ map.name }}</div>
          <div fw-500>{{ +map.amount.toFixed(2) }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
        >
          <div fw-400 class="color-light-black">{{ $t("pay.sendMoney") }}</div>
          <div fw-500>{{ info.deliveryAmount }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-between
          text-12
          class="d-flex align-center boder-bottom-line"
        >
          <div fw-400 class="color-light-black">{{ $t("pay.goodsAmount") }}</div>
          <div fw-500>{{ info.totalAmount }}</div>
        </van-col>
        <van-col
          py-12
          span="24"
          justify-end
          class="d-flex align-center"
        >
          <div text-14 fw-400 mr-8>{{ $t("pay.goodsAmount") }}</div>
          <div text-24 fw-500 style="color: rgb(235, 57, 18)">{{ info.totalAmount }}</div>
        </van-col>
      </van-row>
    </div>
    <div
      class="bottom align-center"
      flex
      py-12
      px-16
      style="background-color: #fff"
    >
      <div class="btn-outer" ml-8>
        <van-button class="btn-outline-orange" :disabled="btnState" @click="showCancelDialog">{{
          $t("pay.cancel")
        }}</van-button>
      </div>
    </div>
  </Container>
</template>

<style lang="less" scoped>
.container-body {
  height: 100vh;
}

.img-60 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow-x: hidden;
}

.bottom {
  height: 70px;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.07);
  z-index: 2;
}

.btn-outer {
  width: 100%;
  .btn-outline-orange {
    width: 100% !important;
  }
}

.cost-row .van-col {
  height: 55px;
}

.content-item {
  height: 60px;
}

.goods-body {
  overflow: auto;
}

.goods-title {
  height: 56px;
}

.btn-next {
  .btn-fill-orange {
    width: 100%;
  }
}
</style>
