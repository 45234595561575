<script setup lang="ts">
definePage({
  name: 'userInfoEdit',
  meta: {
    level: 2,
    title: 'userInfoEdit',
    i18n: 'userInfo.name',
  },
})
const { t } = useI18n()
import { showToast } from 'vant';
const formatter = (value: string) => value.trim()

import type { LoginData } from '@/types/user';
const param = reactive<any>({
  id: 0,
  name: ''
});
import { getUserInfo, setUserInfo } from '@/storage/localStorage';
let user: LoginData = getUserInfo()
onMounted(() => {
  param.name = user.name || user.email
})
const router = useRouter()
import { updateAccount } from '@/api/settings'
const onSubmit = async (_values: any) => {
  param.id = user.id
  const { code } = await updateAccount(param)

  if (code == 200) {
    user.name = param.name
    setUserInfo(user)
    router.go(-1)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
};
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-form @submit="onSubmit" style="width: 100%;">
      <van-cell-group inset>
        <van-field v-model="param.name" :clearable="true" clear-trigger="always" maxlength="50" :formatter="formatter" />
      </van-cell-group>
      <div class="ml-4 mr-4 mt-8">
        <van-button class="btn-fill-orange" style="width: 100%;" native-type="submit">{{ $t('userInfo.save')
        }}</van-button>
      </div>
    </van-form>

  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}

:deep(.van-cell-group.van-cell-group--inset) {
  margin: 0 !important;
}
</style>
