<script setup lang="ts">
import countrys from "@/country";
definePage({
    name: 'countryList',
    meta: {
        level: 2,
        title: 'countryList',
        i18n: 'countryList.title',
    },
})

interface Props {
    showAreaCode?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showAreaCode: true
})

import { locale } from '@/utils/i18n'
let countryList = ref<Array<any>>(countrys.cn[locale.value])
const searchCounryList = function (e) {
    countryList.value = e.target.value ? countrys.cn[locale.value].filter(v => v.country_name.includes(e.target.value) || v.phone_code.includes(e.target.value)) : countrys.cn[locale.value]
}
import type { Country } from '@/types/user';
let selected = ref<Country>()
const selectCountry = function (item: Country) {
    selected.value = item
    onBack()
}
const emit = defineEmits(['dialogVisible']);
const onBack = function() {
    emit('dialogVisible', selected.value);
}
</script>

<template>
    <Container class="pl-0 pr-0" style="background-color: #fff;">
        <VanNavBar
            :title='$t("countryList.title")'
            :fixed="true"
            clickable left-arrow
            @click-left="onBack"
        />
        <van-search @input="searchCounryList" :placeholder="$t('countryList.title')" shape="round" :clearable="false" />
        <van-list>
            <van-cell class="list-cell" v-for="item in countryList" :key="item.id" :title="item.country_name"
                :value="props.showAreaCode ? item.phone_code : ''" @click="() => selectCountry(item)" />
        </van-list>
    </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell .van-cell__title) {
    flex: unset;
    width: 70%;
}

:deep(.van-cell .van-cell__value) {
    flex: unset;
    width: 30%;
}

:deep(.van-cell__value.van-field__value) {
    flex: 1;
}
</style>
