<script setup lang="ts">
import CountryList from '@/pages/profile/country-list.vue';
import arrow_down_fill from '@/assets/icon/arrow_down_fill.png';
import { ref } from 'vue';
import { showToast } from 'vant';
definePage({
  name: 'login-submit',
  meta: {
    level: 2,
    title: 'login-submit',
    i18n: 'login.title',
  },
})
const router = useRouter()
const route = useRoute()

let checked = ref<boolean>(false)
import type { Login, Country } from '@/types/user';
let showArea = ref<boolean>(false)
let showPwd = ref<boolean>(false)
  let areaObj = ref<any>({
  "id": 1,
  "country_name": "Nigeria",
  "country_code": "NG",
  "phone_code": "+234"
});
onMounted(() => {
  param.email = route.query.loginAccount as string
  if (route.query.areaCode) {
    areaObj.value = JSON.parse(route.query.areaCode as string) as Country
    if (!areaObj.value.phone_code.startsWith('+')) {
      areaObj.value.phone_code = '+' + areaObj.value.phone_code
    }
  }
  showArea.value = !param.email.includes('@')
  if (route.query.source == '1') {
    checked.value = true
  }
})

let show = ref<boolean>(false)
const dialogVisible = function(e: Country) {
  show.value = false
  areaObj.value = e
}

const inputAccount = function (e: any) {
  showArea.value = !isNaN(parseFloat(e)) && isFinite(e)
}

const param = reactive<Login>({
  "deviceId": new Date().getTime() + '',
  "email": "",
  "pwd": "",
  "sysZoneId": 0,
  "type": 0
});
const { t } = useI18n()
const checkForm = function () {
  if (!checked.value) {
    showToast({
      message: t('login.checkInfo'),
      position: 'bottom',
    });
    return false
  }
  if (param.email == '') {
    showToast({
      message: t('login.fillEmail'),
      position: 'bottom',
    });
    return false
  }
  if (param.pwd == '') {
    showToast({
      message: t('login.fillPwd'),
      position: 'bottom',
    });
    return false
  }
  if (!/^\S+(?:\s+\S+)*$/.test(param.email)) {
    showToast({
      message: `${t('login.email')}${t('login.spaceisNotAllown')}`,
      position: 'bottom',
    });
    return false
  }
  if (/\s+/g.test(param.pwd)) {
    showToast({
      message: `${t('login.password')}${t('login.spaceisNotAllown')}`,
      position: 'bottom',
    });
    return false
  }
  return true
}

import { login, getUserMsg } from '@/api/user'
import {setToken, setUserInfo, setAccountList} from '@/storage/localStorage';
const onSubmit = async () => {
  if (!checkForm()) return
  param.type = param.email.includes('@') ? 1 : 2
  const { code, data } = await login(param)

  if (code == 200) {
    const { token, sellLoginReturnDto } = data
    setToken(token)
    setAccountList(sellLoginReturnDto.email)
    getUserData(sellLoginReturnDto.id)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
};

const getUserData = async function(id: number) {
  const { code, data } = await getUserMsg(id)

  if (code == 200) {
    setUserInfo(data)
    router.push('/')
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code  + '')}`,
    position: 'bottom',
  });
}

</script>

<template>
  <Container class="d-flex justify-between flex-column align-center" style="background-color: #fff;">
    <van-action-sheet v-model:show="show" :round="false">
      <CountryList @dialogVisible="dialogVisible"/>
    </van-action-sheet>
    <van-form style="width: 100%;">
      <van-cell-group inset style="margin: 0;">
        <div class="van-cell van-field van-field--label-top">
          <div class="van-cell__title van-field__label van-field__label--top"><label id="van-field-4-label"
              for="van-field-4-input">{{ $t('login.email') }}</label></div>
          <div class="van-cell__value van-field__value input-container border-container">
            <div class="d-flex align-center" style="height: 100%;width: 100%;">
              <div class="d-flex align-center" @click="show = true">
                <div v-if="showArea">{{ areaObj.phone_code }}</div>
                <img v-if="showArea" :src="arrow_down_fill" class="ml-2 mr-4 img-16" />
              </div>
              <van-field style="flex: 1;" v-model="param.email" label-align="top" :clearable="true" clear-trigger="always"
                @update:model-value="inputAccount" />
            </div>
          </div>
        </div>

        <van-field class="primary-input" v-model="param.pwd" :type="showPwd ? 'text' : 'password'"
          :label="$t('login.password')" label-align="top" :clearable="true" clear-trigger="always"
          :rules="[{ required: true }]" :right-icon="showPwd ? 'eye-o' : 'closed-eye'"
          @click-right-icon="showPwd = !showPwd" />
        <van-cell class="text-end fz-3 fw-400" style="color: rgb(0, 80, 171);" :title="$t('login.forget')" to="index" />
      </van-cell-group>
      <div class="mt-4 mb-4">
        <van-button type="warning" class="subject-btn" @click="onSubmit">{{ $t('login.title') }}</van-button>
      </div>
    </van-form>

    <van-checkbox class="mb-36" v-model="checked" icon-size="12px" checked-color="rgba(255, 74, 33, 1)">{{
      $t('login.check') }}</van-checkbox>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.login-label {
  font-size: min(3.733vw, 22.4px);
  font-weight: 500;
  color: #73767a;
  margin-bottom: var(--van-padding-base);
}

:deep(.van-cell__title.van-field__label.van-field__label--top label) {
  &:extend(.login-label);
}

:deep(.van-field__body) {
  width: 100%;
}

.input-container {
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 500
}

.border-container {
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 1);
}

:deep(.primary-input .van-cell__value.van-field__value) {
  &:extend(.input-container);
  &:extend(.border-container);
}

.subject-btn {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background: rgba(255, 74, 33, 1);
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.van-button__text) {
  display: flex;
  align-items: center;
  width: 100%;
}

:deep(.van-checkbox__label) {
  font-size: 12px;
  font-weight: 400;
  color: rgb(110, 110, 110)
}
:deep(.van-cell:after) {
  border-bottom: unset !important
}
</style>
