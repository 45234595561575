<script setup lang="ts">
definePage({
  name: 'fillCode',
  meta: {
    level: 2,
    title: 'fillCode',
    i18n: 'checkEmail.title2',
  },
})
import phoneCode from '@/components/phoneCode.vue';
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
const user: LoginData = getUserInfo()
let count = ref<number>(60);
const updateCountdown = function () {
  if (count.value > 0) {
    count.value--;
  }
  if (count.value === 0) {
    clearInterval(intervalId);
  }
}
let intervalId = null
onMounted(() => {
  intervalId = setInterval(updateCountdown, 1000);
})

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
import { showToast } from 'vant';
import { codeCheck, sendCode } from '@/api/settings';
import type { SendEmailCodeReq } from '@/types/settings';
let param: SendEmailCodeReq = {
  areaCode: '',
  batch: 1,
  code: null,
  codeId: +route.query.codeId as number,
  fromAddress: user.email,
  id: user.id,
  languageCode: locale.value,
  sysCountryId: user.sysCountryId,
  type: 1
}
const getValue = async function (_code: string) {
  param.code = _code
  const { code } = await codeCheck(param)
  if (code == 200) {
    router.push(`setNewEmail`)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const sendCodeAgain = async function () {
  if (count.value != 0) return
  intervalId = setInterval(updateCountdown, 1000);
  const { code, data } = await sendCode(param)
  if (code == 200) {
    param.codeId = data.emailCodeId
    router.push(`setNewEmail`)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}
</script>

<template>
  <Container class="d-flex flex-column" style="background-color: #fff;">
    <div class="mt-10 fz-4 fw-500 color-black">{{ $t('checkEmail.code') }}</div>
    <div class="mt-4 color-light-black">
      <span>{{ $t('checkEmail.info2') }}</span>
      <span class="pl-2 pr-2">{{ user.email }}</span>
      <span>{{ count }}s</span>
    </div>
    <phoneCode class="mb-5 mt-5" @getValue="getValue" />
    <div :class="count != 0 ? 'disabled' : ''" class="btn-send fontsize-12 fw-400 text-end"
      style="color: rgb(0, 80 ,171);" @click="sendCodeAgain">{{ $t('checkEmail.reSend') }}</div>
  </Container>
</template>

<style lang="less" scoped>
.btn-send.disabled {
  color: rgb(148, 146, 146) !important
}
</style>
