<script setup lang="ts">
definePage({
    name: 'secondary-list',
    meta: {
        level: 2
    },
})
const { t } = useI18n()
import { showToast } from 'vant';
import { getUserInfo, setShopId, setShopInfo, setShopName } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import type { addNextShopReq, addShopReq } from '@/types/shop';
import { shopList, rememberUserShop } from '@/api/shop';
const userinfo: LoginData = getUserInfo()
const route = useRoute()
const q: addShopReq = {
    nuserId: userinfo.id,
    sysTenantId: +route.query.sysTenantId as number
}
onMounted(() => {
    getList()
})

let map = reactive({})
const getList = async function () {
    const { code, data } = await shopList(q)

    if (code == 200) {
        for (let item of data) {
            if (item.setAreaName in map) {
                map[item.setAreaName].push(item)
            } else {
                map[item.setAreaName] = [item]
            }
        }
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
}

const router = useRouter()
const selectShop = async function (item) {
    const param: addNextShopReq = {
        nshopSetId: item.nshopSetId as number,
        nuserId: userinfo.id,
        sysTenantId: +route.query.sysTenantId as number
    }
    const { code } = await rememberUserShop(param)

    if (code == 200) {
        setShopId(item.id)
        setShopName(item.nshopName)
        setShopInfo(item)
        router.push("/")
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
}
</script>

<template>
    <Container class="pl-0 pr-0">
        <div class="pl-4 pr-4 pt-3 pb-3 fz-3 fw-400" style="background-color: #fff;" v-for="(value, key) of map" :key="key">
            <div class="fz-3 fw-400 color-black">{{ key }}</div>
            <div class="d-flex align-center justify-between" style="width: 100%;" v-for="item of value" @click="selectShop(item)">
                <div class="d-flex align-center justify-between pt-2 pb-2" style="width: 100%;">
                    <div>
                        <div class="color-black fz-3 fw-400">
                            {{ item.nshopName }}
                        </div>
                        <div class="mb-1 color-light-black fz-3 fw-400">
                            <span>{{ $t('shop.hotPhone') }}</span>&ensp;<span>{{ item.telephone }}</span>
                        </div>
                        <div class="mb-1 color-light-black fz-3 fw-400 flex" style="word-break: break-all;">
                            <div class="mr-1">{{ $t('shop.addr') }}</div>
                            <div style="flex: 1;">{{ item.addr }}</div>
                        </div>
                    </div>
                    <van-icon name="success" class="color-orange" v-if="item.isDefalut == 1" />
                </div>
                <van-divider class="mt-2 mb-2" />
            </div>
            <van-divider class="mt-2 mb-2" />
        </div>
    </Container>
</template>

<style lang="less" scoped>
:deep(.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon) {
    color: #fff;
}

:deep(.check .van-checkbox__icon--disabled .van-icon) {
    background-color: var(--color-orange);
    border-color: var(--color-orange);
}

:deep(.not-check .van-checkbox__icon--disabled .van-icon) {
    background-color: #fff;
}
</style>
