<script setup lang="ts">
definePage({
  name: 'qrcode',
  meta: {
    level: 2,
    title: 'qrcode',
    i18n: 'userInfo.qrcode',
  },
})
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import QRCode from 'qrcode'
const qrCanvas = ref(null);
onMounted(()=> {
  bindQRCode();
})
const bindQRCode = function() {
  const user: LoginData = getUserInfo()
  let url = `email:${user.email}`;
  QRCode.toCanvas(qrCanvas.value, url, error => {
    if (error) console.error(error);
  });
}
const router = useRouter()
function onBack() {
  router.go(-1)
}
</script>

<template>
  <Container class="qrcode-container">
    <VanNavBar
      :title='$t("userInfo.qrcode")'
      :fixed="true"
      clickable left-arrow
      @click-left="onBack"
    />
    <div>
      <canvas ref="qrCanvas" class="qrcode-inner"></canvas>
    </div>
  </Container>
</template>
<style scoped lang="less">
:deep(.van-nav-bar__content) {
  background: rgb(50, 50, 50) !important;
}
:deep(.van-nav-bar__title) {
  color: #fff !important
}
:deep(.van-nav-bar .van-icon) {
  color: #fff !important
}
:deep(.van-hairline--bottom:after) {
  border-color: unset !important;
}
.qrcode-container {
  background-color: rgb(50, 50, 50);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0 !important;
  z-index: 999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode-inner {
  width: 240px !important;
  height: 240px !important;
}
</style>
