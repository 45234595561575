<script setup lang="ts">
definePage({
  name: 'setNewEmail',
  meta: {
    level: 2,
    title: 'setNewEmail',
    i18n: 'email.title',
  },
})
const { t } = useI18n()
import { showToast } from 'vant';
const formatter = (value: string) => value.trim()
let checked = ref<boolean>(false)

import type { LoginData } from '@/types/user';
import { getUserInfo } from '@/storage/localStorage';
let user: LoginData = getUserInfo()
import { sendCode, codeCheck } from '@/api/settings';
import type { SendEmailCodeReq } from '@/types/settings';
let param = ref<SendEmailCodeReq>({
  areaCode: '',
  batch: 2,
  code: '',
  codeId: null,
  fromAddress: '',
  id: user.id,
  languageCode: locale.value,
  sysCountryId: user.sysCountryId,
  type: 1
})
const router = useRouter()
const onSubmit = async (_values: any) => {
  if (param.value.fromAddress == '') {
    showToast({
      message: t('email.fillEmail'),
      position: 'bottom',
    });
    return
  }
  if (param.value.code == '') {
    showToast({
      message: t('email.fillCode'),
      position: 'bottom',
    });
    return
  }
  const { code } = await codeCheck(param.value)
  if (code == 200) {
    router.push(`accountManage`)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
};

let count = ref<number>(60);
const updateCountdown = function () {
  if (count.value > 0) {
    count.value--;
  }
  if (count.value === 0) {
    clearInterval(intervalId);
  }
}
let intervalId = null

const getEmailCode = async function () {
  intervalId = setInterval(updateCountdown, 1000);
  const { code, data } = await sendCode(param.value)
  if (code == 200) {
    param.value.codeId = data.emailCodeId
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}
</script>

<template>
  <Container class="pl-0 pr-0" style="background-color: #fff;">
    <van-form class="mt-10" @submit="onSubmit" style="width: 100%;">
      <van-cell-group inset>
        <van-field v-model="param.fromAddress" :label='$t("email.name")' label-align="top" maxlength="20"
          :formatter="formatter" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="param.code" type="number" :label='$t("email.code")' label-align="top" maxlength="6"
          :formatter="formatter">
          <template #extra>
            <van-button size="small" :disabled="count != 60 || !/^[^@]+@[^@]+$/.test(param.fromAddress)" class="color-orange" @click="getEmailCode">{{ count == 60 ?
              $t('email.send') : count + 's' }}</van-button>
          </template>
        </van-field>
      </van-cell-group>
      <van-checkbox class="mb-40 mt-4 pl-4 pr-4" v-model="checked" icon-size="12px"
        checked-color="rgba(255, 74, 33, 1)">{{
          $t('email.tips') }}</van-checkbox>
      <div class="ml-4 mr-4 mt-8">
        <van-button class="btn-fill-orange" :disabled="!checked || !param.codeId" style="width: 100%;"
          native-type="submit">{{ $t('email.submit')
          }}</van-button>
      </div>
    </van-form>

  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell__value.van-field__value) {
  flex: 1;
}

:deep(.van-cell:after) {
  right: 0 !important;
  left: 0 !important;
}

:deep(.van-cell-group.van-cell-group--inset) {
  margin: 0 !important;
}

:deep(.color-orange.van-button) {
  border: unset
}</style>
