<script lang="ts">
	import { defineComponent } from 'vue';
	export default defineComponent({
		beforeRouteEnter(_to, from, next) {
      let arr = ['/', '/car/orderInfoUnPay', '/profile/order/info', '/profile/order/list']
      if (!arr.includes(from.path)) {
        next(false)
        return
      }
			next();
		},
	});
</script>
<script setup lang="ts">
import { getOrderInfo, getShopId, getShopName, setOrderInfo } from "@/storage/localStorage";
import {
  sellGetPay,
  toPay,
} from "@/api/goodsCar";
import { showToast } from "vant";
import type { PayMethod } from "@/types/order";
import type { NordersPayList, ToPayReq } from "@/types/goodsCar";
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
definePage({
  name: "selectPayMethod",
  meta: {
    level: 2,
    title: "selectPayMethod",
    i18n: "countryList.title",
  },
});
const { t } = useI18n();
const router = useRouter();
const onBack = function () {
  router.back();
};


onMounted(() => {
  getMethod()
});


const list = ref<PayMethod[]>([]);
const getMethod = async () => {
  const { code, data } = await sellGetPay();
  if (code == 200) {
    list.value = data;
    for (let item of list.value) {
      item.isCheck = 2
    }
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

let btnState = ref<boolean>(false);
const orderInfo = getOrderInfo()

const clickDefault = async function (select: PayMethod) {
  for (let item of list.value) {
    item.isCheck = item.setPayTypeId === select.setPayTypeId ? 1 : 2
  }
}


const userinfo: LoginData = getUserInfo()
const submit = async function () {
  if (list.value.filter(v=>v.isCheck === 1).length === 0) {
    showToast({
      message: t("pay.methodInfo"),
      position: "bottom",
    });
    return
  }
  if (btnState.value) return;
  btnState.value = true;

  const time = new Date().getTime()
  let pays: NordersPayList[] = []
  for (let item of list.value) {
    const temp: NordersPayList = {
      amount: orderInfo.totalAmount,
      batchNum: 0,
      factAmount: 0,
      id: null,
      incomeTime: time,
      nordersId: orderInfo.id,
      nuserId: userinfo.id,
      perFee: 0,
      setPayTypeId: item.setPayTypeId,
      setPayTypeName: item.setPayTypeName,
      shopId: getShopId(),
      shopName: getShopName(),
      type: 1,
      updateTime: time,
      userDutyId: null,
      userName: userinfo.name
    }
    pays.push(temp)
  }
  const param: ToPayReq = {
    id: null,
    nordersId: orderInfo.id,
    payAmount: orderInfo.totalAmount,
    payBy: 1,
    payList: pays
  }
  const { code, data } = await toPay(param);
  if (code == 200) {
    setOrderInfo(data)
    router.push("/car/orderInfoDisplay")
    return;
  }
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
}
</script>

<template>
  <Container class="pl-0 pr-0 flex-column container-body" flex justify-between>
    <VanNavBar :title="$t('pay.title')" :fixed="true" clickable left-arrow @click-left="onBack">
      <template #left>
        <van-icon name="cross" size="22" />
      </template>
    </VanNavBar>
    <div style="flex: 1" overflow-auto>
      <van-row mx-8 my-16 style="background-color: #fff">
        <van-col span="24" class="d-flex align-center amount boder-bottom-line" justify-center text-24 fw-500
          style="color: rgb(235, 57, 18)">
          {{ orderInfo.totalAmount }}
        </van-col>
        <van-col span="24" class="d-flex color-black" px-16 pt-16 align-center text-16 fw-500>
          {{ $t("pay.method") }}
        </van-col>
        <van-col span="24" v-for="item of list" :key="item.setPayTypeId" class="d-flex boder-bottom-line" py-12 px-16
          justify-between align-center>
          <div flex class="align-center">
            <img :src="item.iconUrl" class="img-24" />
            <div pl-8 text-14 fw-400 color-black>{{ item.setPayTypeName }}</div>
          </div>
          <van-radio-group v-model="item.isCheck" @click="() => clickDefault(item)">
            <van-radio :name="1" icon-size="24px"></van-radio>
          </van-radio-group>
        </van-col>
      </van-row>
    </div>
    <div class="bottom align-center" flex justify-end pt-12 pb-12 pl-16 pr-16 style="background-color: #fff">
      <van-button class="btn-fill-orange" :disabled="btnState" @click="submit">{{
        $t("pay.submit")
      }}</van-button>
    </div>
  </Container>
</template>

<style lang="less" scoped>
.container-body {
  height: 100vh;
}

.bottom {
  height: 70px;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.07);
  z-index: 2;
  .btn-fill-orange {
    width: 100%;
  }
}

.amount {
  height: 77px;
}
</style>
