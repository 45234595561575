<script setup lang="ts">
import account from '@/assets/icon/account.png';
definePage({
  name: 'accounts',
  meta: {
    level: 2,
    title: 'accounts',
    i18n: 'login.title',
  },
})
import { getAccountList, setAccountListObj } from '@/storage/localStorage';
let accounts = ref<Array<string>>(getAccountList())

const router = useRouter()
const toLogin = function (email) {
  localStorage.setItem('loginAccount', email)
  router.push(`login-submit?loginAccount=${email}`)
}
const isDel = ref<boolean>(false)
const showTips = ref<boolean>(false)
const selectedEmail = ref<string>('')
const clickDelete = function (e, item) {
  e.stopPropagation();
  showTips.value = true
  selectedEmail.value = item
}
const deleteAccountItem = function() {
  let i = 0
  for (let index in accounts.value) {
    if (accounts.value[index] == selectedEmail.value) {
      i = +index
      break
    }
  }
  accounts.value.splice(i, 1)
  setAccountListObj(accounts.value)
  showTips.value = false
  if (accounts.value.length == 0) {
    router.push('login')
  }
}
</script>

<template>
  <Container style="background-color: #fff;">
    <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="text-center dialog-title mb-6">{{ $t('countryList.delAccount2') }}</div>
        <div class="dialog-info mb-6">{{ $t('countryList.delAccount2Info') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showTips = false">{{ $t('countryList.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="deleteAccountItem">{{ $t('countryList.confirm') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <div class="mb-8"></div>
    <van-cell :is-link="!isDel" class="other-btn" @click="() => toLogin(item)" v-for="(item, index) of accounts"
      :key="index">
      <template #title>
        <div class="d-flex align-center">
          <img :src="account" class="mr-4 img-24" />
          <div class="custom-title">{{ item }}</div>
        </div>
      </template>
      <template #right-icon v-if="isDel">
        <div @click="(e)=>clickDelete(e, item)" class="fz-3 fw-400 color-lavender">{{ $t('countryList.delete') }}</div>
      </template>
    </van-cell>
    <van-cell class="text-center fz-3 fw-400 color-lavender" v-if="!isDel" :title="$t('countryList.otherLogin')" to="login" />
    <van-cell class="text-center fz-3 fw-500 del-info" @click="isDel = true" :title="$t('countryList.delAccount')" />
  </Container>
</template>

<style lang="less" scoped>
.other-btn {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
  height: 46px;
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 1);
  margin-bottom: 12px;
}

.del-info {
  color: rgb(49, 55, 61);
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.dialog-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
}

.dialog-info {
  font-size: 16px;
  font-weight: 400;
  color: rgba(110, 110, 110, 1);
}
</style>
