<script setup lang="ts">
definePage({
  name: 'car',
  meta: {
    level: 1,
  },
})
import { getCarList, deleteCarGoods, selectAll, select } from '@/api/goodsCar';
import car_default from '@/assets/icon/car_default.png';
import share from '@/assets/icon/share.png';
import { getShopId, getToken } from '@/storage/localStorage';
const { t } = useI18n()
import { showToast } from 'vant';
let number = ref<number>()

onMounted(() => {
  if (getToken()) {
    getAdList()
  }
})

const shopId = getShopId()
import { getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import type { CarListRes } from '@/types/goodsCar';
const userinfo: LoginData = getUserInfo()
let nuserId = null
if (userinfo) {
  nuserId = userinfo.id
}
const list = ref<CarListRes[]>([])
const getAdList = async () => {
  const { code, data } = await getCarList(shopId, nuserId)
  if (code == 200) {
    list.value = data
    number.value = 0
    for (let item of list.value) {
      number.value += item.num
      item.checked = item.isSelected === 1
    }
    const temp = list.value.filter(v => v.checked === true)
    const all = list.value.length
    checked.value = all !== 0 && temp.length === all
    itemTotal.value = 0
    for (let t of temp) {
      itemTotal.value += (t.num * t.showPrice)
    }
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

import GoodsImg from '@/pages/goods/img.vue';
import type { BatchingList, goodsToCarReq } from '@/types/goods';
import { editNCar } from '@/api/home';
const checked = ref<boolean>(false)
let itemTotal = ref<number>(0)

const _selectAll = async function () {
  const { code } = await selectAll(nuserId, shopId, checked.value ? 1 : 2)
  if (code == 200) {
    getAdList()
    return
  }
  checked.value = !checked.value
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const selectItemCheck = async function (item: CarListRes) {
  const { code } = await select(item.detailId, item.checked ? 1 : 2)
  if (code == 200) {
    getAdList()
    return
  }
  item.checked = !item.checked
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

let deleteNumber = ref<number>(0)
const showNumber = ref<boolean>(false)
let actionGoods = ref<CarListRes>()
const focusNumber = function (item: CarListRes) {
  showNumber.value = true
  actionGoods.value = item
  deleteNumber.value = item.num
}

const beforeChange = (value: number, item: CarListRes, isFun: boolean) => {
  actionGoods.value = item
  if ( value === 0) {
    showDelete.value = true
    showNumber.value = false
    return false
  }
  if (!isFun) return true
  return new Promise((resolve) => {
    save(value).then((a: boolean) => {
      resolve(a)
    })
  });
}

const showDelete = ref<boolean>(false)


const deleteGoodsFun = async () => {
  const { code } = await deleteCarGoods(actionGoods.value.detailId)
  if (code == 200) {
    showDelete.value = false
    getAdList()
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const submitDel = function () {
  deleteGoodsFun()
}

const save = async (number: number) => {
  return new Promise(async (resolve) => {
    if (!number || number === 0) {
      showToast({
          message: t('goods.numberIsZero'),
          position: 'bottom',
      });
      resolve(true)
      return
    }
    let _batchs: BatchingList[] = []
    for (let batch of actionGoods.value.detailList) {
      _batchs.push({
          batchingDetailId: batch.batchingDetailId,
          batchingId: batch.batchingId,
          createTime: time,
          id: batch.id,
          nCartDetailId: batch.id,
          num: actionGoods.value.num
      })
    }
    const param: goodsToCarReq = {
        batchingList: _batchs,
        cartId: null,
        createTime: time,
        id: actionGoods.value.detailId,
        ngoodsDetailId: actionGoods.value.ngoodsDetailId,
        ngoodsId: actionGoods.value.ngoodsId,
        num: number,
        nuserId: nuserId,
        shopId: shopId
    }
    const { code } = await editNCar(param)
    if (code == 200) {
        showNumber.value = false
        getAdList()
        resolve(true)
        return
    }
    showToast({
        message: `[${code}]:${t('code.' + code + '')}`,
        position: 'bottom',
    });
    resolve(false)
  });
}

const changeNumberForOne = function () {
  save(deleteNumber.value)
}

const time = new Date().getTime()

let btnState = ref<boolean>(false)
const router = useRouter()
const next = async function() {
  router.push('/car/orderInfoUnPay')
}

</script>

<template>
  <Container class="pa-0 flex-column" flex>
    <van-dialog width="327px" v-model:show="showNumber" :showConfirmButton="false">
      <div ma-20 style="background-color: #fff;">
        <div text-20 fw-500 mb-24 text-center>{{ $t('goods.number') }}</div>
        <div mb-24 text-center>
          <van-stepper v-model="deleteNumber" step="1" min="0" max="9999" integer theme="round" button-size="22" 
          :before-change="(value: number) => beforeChange(value, actionGoods, false)" />
        </div>
        <div flex justify-between>
          <van-button class="btn-outline-orange" @click="showNumber = false">{{ $t('goods.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="changeNumberForOne">{{ $t('goods.submit') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <van-dialog width="327px" v-model:show="showDelete" :showConfirmButton="false">
      <div ma-20 style="background-color: #fff;">
        <div text-center mb-24>{{ $t('goods.delTips') }}</div>
        <div flex justify-between>
          <van-button class="btn-outline-orange" @click="showDelete = false">{{ $t('goods.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="submitDel">{{ $t('goods.submit') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <div class="color-black" pt-16 pb-16 pl-10 pr-10 flex justify-between text-16 fw-500 dark:text-white
      style="background-color: #fff;">
      <div flex align-center>
        <img :src="car_default" alt="" width="24" height="24">
        <span>{{ number > 0 ? `(${number})` : '' }}</span>
      </div>
      <img :src="share" alt="" width="24" height="24">
    </div>
    <div flex class="flex-column list-body" justify-between>
      <div>
        <div v-for="item of list" :key="item.goodsSkuId" mt-12 p-16 flex
          style="min-height:93px;background-color: #fff;min-width: 0;">
          <van-checkbox v-model="item.checked" @click="() => selectItemCheck(item)"></van-checkbox>
          <div flex class="align-center" ml-12 flex-1 style="min-width: 0;">
            <div class="img-60" mr-12>
              <GoodsImg :showType="item.showType" :color="item.color" :picUrl="item.picUrl" />
            </div>
            <div class="flex-column content-item" flex-1 flex justify-between style="min-width: 0;">
              <div>
                <div text-14 fw-500 dark:text-black class="ellipsis-space">
                  {{ item.name }}{{ item.type === 2 ? `(${item.skuName})` : '' }}
                </div>
                <div text-10 fw-500 class="color-light-black ellipsis-space">{{ item.memo }}</div>
              </div>
              <div flex justify-between>
                <div text-12 fw-600 class="color-orange">
                  {{ item.showPrice }}
                </div>
                <div>
                  <van-stepper v-model="item.num" step="1" min="0" max="9999" integer theme="round" button-size="22"
                    @focus="() => focusNumber(item)" :before-change="(value: number) => beforeChange(value, item, true)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length > 0" class="bottom align-center" pa-16 w-full flex justify-between>
        <div>
          <van-checkbox v-model="checked" @click="_selectAll">{{ $t('goods.all2') }}</van-checkbox>
        </div>
        <div flex justify-end class="align-center" style="flex: 1;">
          <div text-24 fw-500 pr-12 class="color-orange">{{ itemTotal }}</div>
          <div class="btn-outer">
            <van-button class="btn-fill-orange" :disabled="btnState" @click="next">{{ $t('goods.next') }}</van-button>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
<style scoped lang="less">
.list-body {
  height: calc(100% - 106px);
}

.img-60 {
  min-width: 60px;
  display: flex;
  align-items: center;
}

.content-item {
  height: 60px;
}

.bottom {
  height: 70px;
  background-color: #fff;
}

.btn-outer {
  min-width: 100px;

  .btn-fill-orange {
    width: 100% !important;
  }
}
</style>