<script setup lang="ts">
definePage({
  name: 'addressEdit',
  meta: {
    level: 2,
    title: 'addressEdit',
    i18n: 'address.edit',
  },
})
import CountryList from '@/pages/profile/country-list.vue';
import arrow_down_fill from '@/assets/icon/arrow_down_fill.png';

let param = ref<Address>({
  city: '',
  firstName: '',
  id: null,
  isDefault: 2,
  lastName: '',
  nuserId: null,
  postCode: '',
  province: '',
  region: '',
  street: '',
  sysCountryId: null,
  telephone: '',
  address: '',
  showDefault: false
});

import lock from '@/assets/icon/lock.png';
import { addOrUpdate, getMsg } from '@/api/settings';
import { getUserInfo } from '@/storage/localStorage';
import type { Country, LoginData } from '@/types/user';
let areaObj = ref<Country>({
  "id": 1,
  "country_name": "Nigeria",
  "country_code": "NG",
  "phone_code": "+234"
});
const userinfo: LoginData = getUserInfo()
const route = useRoute()
const router = useRouter()
onMounted(() => {
  if (!route.query.id) {
    param.value.nuserId = userinfo.id
    delete param.value.id
  } else {
    getOne()
  }
})

import countrys from "@/country";
const getOne = async function () {
  const { code, data } = await getMsg(+route.query.id)

  if (code == 200) {
    param.value = data
    param.value.showDefault = param.value.isDefault == 1
    areaObj.value = countrys.cn[locale.value].find((v: { id: number; })=>v.id == param.value.sysCountryId)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const { t } = useI18n()
import { showToast } from 'vant';
const formatter = (value: string) => value.trim()
const formatterPhone = (value: any) => value.replace(/\D/g, '').trim()
import type { Address } from '@/types/settings';
const onSubmit = async function () {
  param.value.isDefault = param.value.showDefault ? 1 : 2
  const { code } = await addOrUpdate(param.value)

  if (code == 200) {
    router.go(-1)
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}
let show = ref<boolean>(false)
const dialogVisible = function(e: Country) {
  show.value = false
  areaObj.value = e
  param.value.sysCountryId = areaObj.value.id
}
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-action-sheet v-model:show="show" :round="false">
      <CountryList @dialogVisible="dialogVisible"/>
    </van-action-sheet>
    <van-form @submit="onSubmit">
      <van-cell-group inset class="pt-5 mb-3">
        <van-field v-model="areaObj.country_name" :label='$t("address.country")' label-align="top" maxlength="20" is-link
          readonly @click="show = true" />
      </van-cell-group>
      <van-cell-group inset class="mb-3">
        <van-field v-model="param.firstName" :label='$t("address.name1")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipsname1") }]' />
        <van-field v-model="param.lastName" :label='$t("address.name2")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipsname2") }]' />
        <div class="pl-4">
          <div class="van-cell__title van-field__label van-field__label--top">
            <label>{{ $t("address.phone") }}</label>
          </div>
          <div class="d-flex">
            <div class="d-flex align-center">
              <div>{{ areaObj.phone_code }}</div>
              <img :src="arrow_down_fill" class="ml-2 img-16" />
            </div>
            <van-field v-model="param.telephone" maxlength="20"
              :formatter="formatterPhone" type="tel" :rules='[{ required: true, message: $t("address.tipsphone") }]' >
            </van-field>
          </div>
        </div>
        <van-cell />
      </van-cell-group>
      <van-cell-group inset class="mb-3">
        <van-field v-model="param.city" :label='$t("address.city")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipscity") }]' />
        <van-field v-model="param.province" :label='$t("address.province")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipsprovince") }]' />
        <van-field v-model="param.postCode" :label='$t("address.postCode")' label-align="top" maxlength="20"
          :formatter="formatter" type="number" :rules='[{ required: true, message: $t("address.tipspostCode") }]' />
        <van-field v-model="param.region" :label='$t("address.addr1")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipsaddr1") }]' />
        <van-field v-model="param.street" :label='$t("address.addr2")' label-align="top" maxlength="20"
          :formatter="formatter" :rules='[{ required: true, message: $t("address.tipsaddr2") }]' />
        <van-cell />
      </van-cell-group>
      <van-cell-group inset class="mb-3">
        <van-cell :title='$t("address.default")'>
          <template #right-icon>
            <van-switch v-model="param.showDefault" active-color="rgba(255, 96, 0, 1)" size="18"/>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="d-flex">
              <img :src="lock" class="img-20 mr-3"/>
              <div style="color: rgb(37, 157, 95);">{{ $t('address.safeInfo') }}</div>
            </div>
            <div class="color-light-black">
              We maintain industry-standard physical, technical, and administrative measures to safeguard your personal information.
            </div>
          </template>
        </van-cell>
        <div class="d-flex justify-center mt-4 color-light-black">
          <div style="border-bottom: 2px solid var(--color-light-black);width: fit-content;">
            {{ $t('address.info2') }}
          </div>
        </div>
        <div class="ma-4" style="background-color: #fff;">
          <van-button class="btn-fill-orange" style="width: 100%;" native-type="submit">{{ $t('address.submit')
          }}</van-button>
        </div>
      </van-cell-group>
    </van-form>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-cell-group.van-cell-group--inset) {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 0;
}

:deep(.van-cell.van-cell--clickable) {
  padding-bottom: 8px !important;
}

:deep(.van-cell__title.van-field__label label) {
  font-size: 12px;
  color: rgb(118, 115, 122);
}

:deep(.van-popup.van-popup--bottom.van-safe-area-bottom.van-action-sheet) {
  height: 100% !important;
  max-height: 100% !important;
}
</style>