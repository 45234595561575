<script setup lang="ts">
const route = useRoute()
const router = useRouter()

function onBack() {
  if (window.history.state.back)
    history.back()
  else
    router.replace('/')
}

const { t } = useI18n()

const title = computed(() => {
  if (!route.meta)
    return ''

  return route.meta.i18n ? t(route.meta.i18n) : (route.meta.title || route.query.title as string || '')
})

const rightText = computed(() => {
  if (!route.meta)
    return ''

  return route.meta.rightText ? t(route.meta.rightText) : ''
})

function onClickRight() {
  if (route.meta.rightUrl) {
    router.push(route.meta.rightUrl)
  }
}
</script>

<template>
  <VanNavBar
    v-show="title"
    :title="title"
    :fixed="true"
    clickable left-arrow
    @click-left="onBack"
    :right-text="rightText"
    @click-right="onClickRight"
  />
</template>
<style lang="less" scoped>
:deep(.van-nav-bar__text) {
  color: var(--color-light-black) !important
}
</style>
