<script setup lang="ts">
definePage({
    name: 'conments-list',
    meta: {
        level: 2,
        title: 'conments-list',
        i18n: 'orderList.conments'
    },
})
import note from '@/assets/icon/note.png';
import { queryGoodsCommentList } from '@/api/settings';
import type { CommentListReq } from '@/types/settings';
import { showToast } from 'vant';
import { getShopInfo, getUserInfo } from '@/storage/localStorage';
import type { LoginData } from '@/types/user';
import type { ShopInfoRes } from '@/types/shop';
const active = ref(0);
const { t } = useI18n()
const tabs = ref([
    {index: 0, type: 1, isComment: 2, name: t('orderList.conments1')},
    {index: 1, type: 2, isComment: 1, name: t('orderList.conments2')},
])

const route = useRoute()
const shop: ShopInfoRes = getShopInfo()
let userinfo: LoginData = getUserInfo()
let listReq: CommentListReq = {
  isComment: 2,
  isDelivery: +route.query.isDelivery || 1,
  nuserId: userinfo.id,
  pageNum: 1,
  pageSize: 9999999,
  shopId: shop.id,
  sysTenantId: shop.sysTenantId,
  type: 5
}

onMounted(()=>{
  getList()
})

const list = ref([])
const getList = async () => {
  list.value = []
  const { code, data } = await queryGoodsCommentList(listReq)
  if (code == 200) {
    list.value = data
    return
  }
  showToast({
    message: `[${code}]:${t('code.' + code + '')}`,
    position: 'bottom',
  });
}

const toOrderList = function() {
  listReq.isComment = tabs.value[active.value].isComment
  getList()
}

import GoodsImg from "@/pages/goods/img.vue";

import moment from 'moment' //导入模块
moment.locale('zh-cn')

import { updateOrder } from '@/api/settings';
import type { CancelOrderReq } from '@/types/settings';
let btnState = ref<boolean>(false);
const cancelOrder = async () => {
  if (btnState.value) return;
  btnState.value = true;

  const param: CancelOrderReq = {
    nordersId: actionOrder.nordersId,
    nuserId: getUserInfo().id,
    operation: 2
  }
  const { code } = await updateOrder(param);
  if (code == 200) {
    btnState.value = false;
    showTips.value = false
    getList()
    return;
  }
  btnState.value = false;
  showToast({
    message: `[${code}]:${t("code." + code + "")}`,
    position: "bottom",
  });
};

let actionOrder = null
const showTips = ref<boolean>(false)
const router = useRouter()
const toInfo = (order: any) => {
  router.push(`/profile/order/editeConments?data=${JSON.stringify(order)}`)
}
</script>

<template>
  <Container class="pl-0 pr-0">
    <van-dialog width="327px" v-model:show="showTips" :showConfirmButton="false">
      <div class="ma-5" style="background-color: #fff;">
        <div class="text-center dialog-title mb-6">{{ $t('settings.delAccount2') }}</div>
        <div class="dialog-info mb-6">{{ $t('orderList.delAccount2Info') }}</div>
        <div class="d-flex justify-between">
          <van-button class="btn-outline-orange" @click="showTips = false">{{ $t('orderList.cancel') }}</van-button>
          <van-button class="btn-fill-orange" @click="cancelOrder" :disabled="btnState">{{ $t('orderList.confirm') }}</van-button>
        </div>
      </div>
    </van-dialog>
    <div class="d-flex flex-column pa-0">
      <van-tabs v-model:active="active" shrink @click="toOrderList" title-active-color="#ff6000">
        <van-tab v-for="(item, index) of tabs" :key="index" :title="item.name">
        </van-tab>
      </van-tabs>
    </div>
    <div v-for="(order, o) of list" :key="o" pa-16 bg-white mb-16 @click="()=>toInfo(order)">
      <div flex justify-between mb-16 class="align-center">
        <div flex justify-between text-16 fw-500 class="align-center color-black" style="width: 85%;">{{ order.code }}</div>
        <img :src="note" alt="" w-20 h-20/>
      </div>
      <div flex pb-16 class="align-center boder-bottom-line" style="min-width: 0; width: 100%">
        <div w-60 h-60 mr-12>
            <GoodsImg
            :showType="order.showType"
            :color="order.color"
            :picUrl="order.picUrl"
            />
        </div>
        <div
            class="flex-column content-item"
            flex-1
            flex
            justify-between
            style="min-width: 0"
        >
            <div flex justify-between class="align-center">
                <div class="ellipsis-space" text-12 fw-400 style="width: 70%">
                    {{ order.name
                    }}{{ order.type === 2 ? `(${order.skuName})` : "" }}
                </div>
            </div>
            <div class="color-light-black ellipsis-space" text-12 fw-400>
                <span mr-4 :style="`opacity: ${order.batchingName ? 1 : 0}`">+</span>
                <span>{{ order.batchingName }}</span>
            </div>
            <div class="color-light-black" text-12 fw-400>
                <span>{{ order.num }}</span>
                <span> x </span>
                <span v-if="+order.batchingPrice > 0">
                    ( {{ order.price }} + {{order.batchingPrice}} )
                </span>
                <span v-else>{{ order.price }}</span>
            </div>
        </div>
      </div>
      <div mt-16 text-12 fw-400 v-if="listReq.isComment == 1">{{ order.commentList[0].comment }}</div>
    </div>
  </Container>
</template>

<style lang="less" scoped>
:deep(.van-tab.van-tab--line.van-tab--shrink) {
  width: 150px;
}
:deep(.van-tabs__line) {
  width: 150px;
}
:deep(.van-search__content.van-search__content--round) {
  background-color: #fff;
}
</style>
